.partners {
    background-color: $white;
    background-image: url(/img/waves/waves-white-xdkblue-1.svg);
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-bottom: 10rem;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding-bottom: 3rem;
    }
    @media(min-width:$screen-lg) {
        min-height: 700px;
    }

    a {
        font: inherit;
    }


    p {
        font-size: 1.0625rem;
        line-height: 1.59375rem;
    }

    .partner {
        list-style: none;
        text-align: center;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        // padding:5% 0;
        // box-shadow: 0 0 2px $gray;
        // box-shadow: -3px 4px 4px -2px $gray;

        img {
            // max-width:75%;
        }

    }

}