.testimonials {
    background-color:$medium-blue;
    background-image:url("../img/waves/wave-testimonials-top.png"), url("../img/waves/wave-testimonials-bottom.png"), url("../img/icons/work/work-detail-quote.svg");
    background-position:top center, bottom center, center center;
    background-size:contain, contain, 50% 50%;
    background-repeat: no-repeat;
    // background-image: url("../img/icons/work/work-detail-quote.svg");
    // background-position:center center;
    // background-size:60% 60%;
    // background-repeat: no-repeat;
    min-height:55vh;
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        min-height:375px;
    }

    b {
       font-size:1.5rem;
       text-transform:none; 
    }
}