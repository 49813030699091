.passionate {
    background-color: $white;
    background-image: url("../img/waves/wave-passionate-top.png"), url("../img/waves/wave-passionate-bottom.png"), url("/img/content/team/team-passionate-bg-img.jpg");
    background-position: top center, bottom center, center center;
    background-size: contain, contain, cover;
    background-repeat: no-repeat;
    min-height: 65vw;
    @media (min-width:$screen-lg)  {
        min-height: 38vw;
    }
    @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
        min-height: 50vh;
    }
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-image: url("../img/waves/wave-passionate-bg-mobile.png");
        background-size: cover;
        min-height: 60vh;
    }

    .passionate-container {
        margin: 200px auto;
        @media (min-width:$screen-xs) and (max-width:$screen-md) {
            margin: unset;
        }

    }

    h1 {
        font-size: 5.625rem;

        @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
            font-size: 4.4375rem;
        }

        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size: 3.4375rem;
        }
    }

    p {
        margin: 2.5rem;

        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            margin: 1.5rem;
        }
    }

}