.map-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 825px;
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding-bottom: 425px;
    }

    iframe {
        position: absolute;
        left: 0; top: 0;
        height: 100% !important;
        width: 100% !important;
    }
}
 