/* Common styles of menus */
.menu {

    .menu-wrapper {
      position:fixed;
      top:68px;
      left:0px;
      //right:-100%;
      width: 100%;
      height:100%;
      //background-image: linear-gradient(rgba(13,36,65,1.0),rgba(13,36,65,0.0)), url("../images/global-navigation-background.jpg");
      //background-size:cover;
      //background-position: center center;
      //background-repeat: repeat-x;
      background:$dark-blue;
      padding: 0 0;
      overflow: hidden;
      transition:opacity 0.4s ease;
      opacity:0;
      z-index:-1;
  
      //&:before {
      //  position:absolute;
      //  top:0;
      //  left:0;
      //  background-image:url("../images/global-navigation-background.jpg"),linear-gradient(rgba(13,36,65,1.0),rgba(13,36,65,0.0));
      //  background-size:cover;
      //}
  
      &.active {
        opacity:1;
        z-index:1000;
        //right:0%;
      }
  
      .layer {
        // position:absolute;
        // top:0;
        // left:0;
        // width:100%;
        // height:100%;
        // background-image:url("../images/dots-blue.svg");
        // background-size:10%;
        // opacity:0.99;
      }
  
      ul {
        list-style:none;
        text-align:center;
        margin:0 auto;
        max-width:1440px;
        padding: 0;
        @media(max-width:$screen-md) {
          -webkit-padding-start:0
        }
        li {
          display:block;
         
        }
      }
  
      a {
        &:after {
          background:transparent;
        }
      }
  
      .main-menu {
        // margin-top:15rem;
        height:75vh;
  
        @media(max-width:$screen-md) {
          // margin-top:3rem;
          //text-align:left;
        }
  
        @media all and (orientation:landscape) {
          margin-top:10%;
        }
  
        li {
          // margin:0 2rem;
          @include RobotoCondensedRegular;
  
          @media(min-width:$screen-sm) and (max-width:$screen-md){
            display:block;
            margin:0 40px;
            line-height:2;
          }
          @media(min-width:$screen-xs-max) {
            display:block;
            margin: 0 20px;
          }
  
          @media all and (orientation:landscape) {
            display:inline-block;
            // margin:1rem 2rem;
          }
  
          a {
            color:$white;
            text-decoration: none;
            text-transform: uppercase;
            font-size:25px;
            letter-spacing: 1.5px;
            transition:color 0.5s linear;
  
  
            &:hover {
              color:$neon-green;
            }
          }
        }
      }
      .sub-menu {
        margin-top:3rem;
        @media (min-width:$screen-xs) and (max-width:$screen-sm-max) {
          margin-top:3rem;
          text-align:left;
        }
        li {
          margin: 0 20px;
          @media(max-width:$screen-sm-max) {
            margin: 0 40px;
          }
          @media(max-width:$screen-xs-max) {
            margin: 0 20px;
          }
          a {
            @include RobotoCondensedBold;
            display:inline;
            color: $neon-green;
            text-decoration: none;
            text-transform: uppercase;
  
            @media(max-width:$screen-xs) {
              font-size:16px;
            }
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .social-menu {
        // position: absolute;
        // bottom:10em;
        // left:50%;
        // transform:translateX(-50%);
  
        @media(max-width:$screen-md) {
          // position: absolute;
          // bottom:5em;
        }
  
        @media all and (orientation:landscape) {
          // bottom:20%;
        }
  
        li {
          margin: 0 1rem;
          display:inline;
          @media (min-width:$screen-sm) and (max-width:$screen-xs-max) {
            // margin: 0 20px;
          }
          a {
            text-decoration: none;
              i {
                font-size:1.5rem;
                &::before {
                  color:$neon-green;
                }
              }
          }
        }
      }
  
    }
  
  }
  