.extra-dark-blue {
    &.color {
      color:$extra-dark-blue;
    }
    &.bg {
      background:$extra-dark-blue;
    }
  }
  .dark-blue {
    &.color {
      color:$dark-blue;
    }
    &.bg {
      background:$dark-blue;
    }
  }
  
  .medium-blue {
    &.color {
      color:$medium-blue;
    }
    &.bg {
      background:$medium-blue;
    }
  }
  
  .light-blue {
    &.color {
      color:$light-blue;
    }
    &.bg {
      background:$light-blue;
    }
  }
  
  .gray {
    &.color {
      color:$gray;
    }
    &.bg {
      background:$gray;
    }
  }
  
  .light-gray {
    &.color {
      color:$light-gray;
    }
    &.bg {
      background:$light-gray;
    }
  }
  
  .white {
    &.color {
      color:$white;
    }
    &.bg {
      background:$white;
    }
  }
  
  .neon-green {
    &.color {
     color:$neon-green;
    }
    &.bg {
      background:$neon-green;
    }
  }
  
  .bright-blue {
    &.color {
      color:$bright-blue;
    }
    &.bg {
      background:$bright-blue;
    }
  }
  
  .bright-red {
    &.color {
      color:$bright-red;
    }
    &.bg {
      background:$bright-red;
    }
  }
  
  .yellow {
    &.color {
      color:$yellow;
    }
    &.bg {
      background:$yellow;
    }
  }
  
  .white {
    &.color {
      color:$white;
    }
    &.bg {
      background:$white;
    }
  }