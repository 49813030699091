
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
//////////////////////// HERO  ////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////
// main-hero sj 
.hero {
  background-repeat:no-repeat;
  background-position: center bottom;
  background-size: cover, cover;
  background-color:#fff;
  // height: 40vh;
  min-height: 550px;
  @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
    // height: 33vh;
    min-height:450px;
    max-height: 500px;
  }
  @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
    min-height:300px;
    max-height: 500px;
  }

  h1 {
    font-size:138px;
    line-height:160px;

    // @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
    //   margin-top: 100px;
    // }


    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      font-size:80px;
      line-height:100px;

    }

  }

  p {
    @include NunitoMedium;
    color:#fff;
    font-size: 29px;
    // max-width: 780px;
    width: 70%;
    text-align: center;
    margin: auto;
    @media (max-width: $screen-sm) {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      // min-width: 340px;
    }
  }





  p {

    // background-color: red;
    width: 70%;
    max-width: 780px;
    text-align: center;
    margin: auto;
  }


  &.work {
    background-image:url(/img/heroes/work-hero.jpg);
    background-position:bottom center;
    background-size:cover;
    background-color:#042337;
    // background-image: url(/img/heroes/work-hero.jpg);
    // background-position: top center;
    // background-size:cover;


    // @media (min-width:1024px)  {
    //   background-image:url(/img/heroes/work-hero.jpg);
    //   background-position:bottom center;
    //   background-size:cover;
    //   background-color: #36bbf4;
    // }

    @media (min-width:$screen-xs) and (max-width:$screen-sm-max) {
      background-image: url(/img/heroes/work-hero-m.png);
      background-position:bottom center;
      background-size:cover;
      background-color: #36bbf4;
    }
  }

  &.case-study {

    &.sn {
      background-color: $white;
      background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/sn-case-study-hero.jpg);
      background-size:contain, cover;
      background-position: center bottom, center 45%;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-position: center bottom, 90% bottom;
      }
      .logo {
        // width:330px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          // width:266px;
        }
      }
    }
    &.gng {
      background-color: $white;
      background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/gng-case-study-hero.jpg);
      background-size:contain, cover;
      background-position: center bottom, center 45%;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-position: center bottom, 90% bottom;
      }
      .logo {
        width:330px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          width:266px;
        }
      }
    }
    &.fadv {
      background-color: $white;
      background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/fadv-case-study-hero.jpg);
      background-size:contain, cover;
      background-position: center bottom, center 45%;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/fadv-case-study-hero-mb.jpg);
        background-position: center bottom, 90% bottom;
      }
      .logo {
        width:425px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          width:266px;
        }
      }
    }
    &.world {
      background-color: $white;
      background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/world-case-study-hero.jpg);
      background-size:contain, cover;
      background-position: center bottom, center 45%;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/world-case-study-hero-mb.jpg);
        background-position: center bottom, 90% bottom;
      }
      .logo {
        width:375px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          width:266px;
        }
      }
    }
  }

  &.team {
    background-color: $white;
    background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/team-hero.jpg);
    background-size:contain, cover;
    background-position: center bottom, center 45%;
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      background-position: center bottom, 90% bottom;
    }
    // @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
    //   background-image: url(/img/heroes/team-hero-m.jpg);
    // }
  }

  &.services {
    background-color:$white;
    background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/services-hero-2.jpg);
    background-position: center bottom, top center;
    background-size:contain, cover;
    p {
      margin: 0 auto;
    }

    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      background-image:url(/img/waves/services-hero-wave.png), url(/img/heroes/services-hero-m.jpg);
      background-position: center bottom, top center;
      background-size:contain, cover;
  
    }
  }
 /// Temporary  Style Block . Pages needs to be designed 

  &.careers {
    background-color: $white;
    background-image: url(/img/waves/home-primaryfeature2-gradientwaves-1-sn.svg), url(/img/heroes/careers-hero.jpg);
    background-size:contain, cover;
    background-position: center bottom, 70% bottom;
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      background-image: url(/img/waves/home-primaryfeature2-gradientwaves-1-sn.svg), url(/img/heroes/careers-hero-m.jpg);
      background-position: center bottom, 90% bottom;
    }
  }
  &.about {
    background-color: $white;
    background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/about-hero.jpg);
    background-size:contain, cover;
    background-position: center bottom, 70% center;
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      background-position: center bottom, 90% center;
    }
  }
  &.insights {
    background-color: $white;
    background-image:url(/img/waves/services-hero-wave.png), url(/img/heroes/insights-hero.jpg);
    background-size:contain, cover;
    background-position: center bottom,center 34%;
    text-align: center;


  }
  &.contact {
    background-color: $white;
    background-image: url(/img/waves/services-hero-wave.png), url(/img/heroes/contact-hero.jpg);
    background-size:contain, cover;
    background-position: center bottom, center center;
    // min-height:40vh;
    // @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
    //   background-position: center bottom, 90% bottom;
    // }
    @media (max-width:$screen-sm) {
      background-image: url(/img/waves/services-hero-wave.png),url(/img/heroes/contact-hero-m.jpg);
      background-position: center bottom, center bottom;
      // min-height: 350px;
    }
  }
  
  
}
