$font-path:    '../../fonts';

/********************************
        BrandonText
*********************************/

@font-face {
  font-family: 'RobotoRegular';
  src: url('#{$font-path}/roboto/Roboto-Regular.eot');
  src: url('#{$font-path}/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/roboto/Roboto-Regular.woff') format('woff'),
  url('#{$font-path}/roboto/Roboto-Regular.ttf') format('truetype'),
  url('#{$font-path}/roboto/Roboto-Regular.svg#RobotoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'RobotoBlack';
  src: url('#{$font-path}/roboto/Roboto-Black.eot');
  src: url('#{$font-path}/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/roboto/Roboto-Black.woff') format('woff'),
  url('#{$font-path}/roboto/Roboto-Black.ttf') format('truetype'),
  url('#{$font-path}/roboto/Roboto-Black.svg#RobotoBlack') format('svg');
  font-weight: 900;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'RobotoCondensedRegular';
  src: url('#{$font-path}/roboto/RobotoCondensed-Regular.eot');
  src: url('#{$font-path}/roboto/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/roboto/RobotoCondensed-Regular.woff') format('woff'),
  url('#{$font-path}/roboto/RobotoCondensed-Regular.ttf') format('truetype'),
  url('#{$font-path}/roboto/RobotoCondensed-Regular.svg#RobotoCondensedRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'RobotoCondensedBold';
  src: url('#{$font-path}/roboto/RobotoCondensed-Bold.eot');
  src: url('#{$font-path}/roboto/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/roboto/RobotoCondensed-Bold.woff') format('woff'),
  url('#{$font-path}/roboto/RobotoCondensed-Bold.ttf') format('truetype'),
  url('#{$font-path}/roboto/RobotoCondensed-Bold.svg#RobotoCondensedBold') format('svg');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+000-5FF;
}


/********************************
          NUNITO
*********************************/

@font-face {
  font-family: 'NunitoMedium';
  src: url('#{$font-path}/nunito/Nunito-Medium.woff') format('woff');
  src: url('#{$font-path}/nunito/Nunito-Medium.eot?#iefix') format('embedded-opentype'),
       url('#{$font-path}/nunito/Nunito-Medium.woff') format('woff'),
       url('#{$font-path}/nunito/Nunito-Medium.ttf') format('truetype'),
       url('#{$font-path}/nunito/Nunito-Medium.svg#NunitoMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  unicode-range: U+000-5FF;
}


@font-face {
  font-family: 'NunitoRegular';
  src: url('#{$font-path}/nunito/Nunito-Regular.eot');
  src: url('#{$font-path}/nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/nunito/Nunito-Regular.woff') format('woff'),
  url('#{$font-path}/nunito/Nunito-Regular.ttf') format('truetype'),
  url('#{$font-path}/nunito/Nunito-Regular.svg#NunitoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'NunitoSemiBold';
  src: url('#{$font-path}/nunito/Nunito-SemiBold.eot');
  src: url('#{$font-path}/nunito/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/nunito/Nunito-SemiBold.woff') format('woff'),
  url('#{$font-path}/nunito/Nunito-SemiBold.ttf') format('truetype'),
  url('#{$font-path}/nunito/Nunito-SemiBold.svg#NunitoSemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: 'NunitoBold';
  src: url('#{$font-path}/nunito/Nunito-Bold.eot');
  src: url('#{$font-path}/nunito/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$font-path}/nunito/Nunito-Bold.woff') format('woff'),
  url('#{$font-path}/nunito/Nunito-Bold.ttf') format('truetype'),
  url('#{$font-path}/nunito/Nunito-Bold.svg#NunitoBold') format('svg');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+000-5FF;
}

/********************************
        Custom Font Icons
*********************************/


@font-face {
  font-family: 'mq';
  src:  url('#{$font-path}/mq-icons/mq.eot?e2klq3');
  src:  url('#{$font-path}/mq-icons/mq.eot?e2klq3#iefix') format('embedded-opentype'),
    url('#{$font-path}/mq-icons/mq.ttf?e2klq3') format('truetype'),
    url('#{$font-path}/mq-icons/mq.woff?e2klq3') format('woff'),
    url('#{$font-path}/mq-icons/mq.svg?e2klq3#mq') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="mq-"], [class*=" mq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  //
  font-family: 'mq';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mq-dropdown-arrow:before {
  content: "\e909";
  color: #fff;
}

.mq-facebook:before {
  content: "\e900";
  color: #fff;
}
.mq-linkedin:before {
  content: "\e901";
  color: #fff;
}
.mq-twitter:before {
  content: "\e902";
  color: #fff;
}

@font-face {
	font-family: 'feather';
	src:url('#{$font-path}/feather/feather.eot?-ajbl0g');
	src:url('#{$font-path}/feather/feather.eot?#iefix-ajbl0g') format('embedded-opentype'),
		url('#{$font-path}/feather/feather.ttf?-ajbl0g') format('truetype'),
		url('#{$font-path}/feather/feather.woff?-ajbl0g') format('woff'),
		url('#{$font-path}/feather/feather.svg?-ajbl0g#feather') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'feather';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon--zoom:before {
	content: "\e036";
}
.icon--circle-cross:before {
	content: "\e602";
}
.icon--arrow-right:before {
	content: "\e600";
}
.icon--arrow-left:before {
	content: "\e601";
}
