.solution {
    background-color:$light-gray;
    background-image:url("../img/waves/contact-divider-wave-01-01.svg");
    background-position:bottom center;
    background-size:contain;
    background-repeat: no-repeat;
    min-height:50vh;
    padding-bottom:10%;
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        background-image:url("../img/waves/wave-case-study-mb.png");
    }
    .cell {
        &.dashed {
            border-right: 2px dashed $hover-neon-green;
            @media(min-width:$screen-xs) and (max-width:$screen-sm) {
                height:75px;
            }
        }
        
        img {
            width:25vw;
            // padding:2rem;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                width:50vw;
            }
        }

        h6 {
           font-size: 2.09375rem;
           letter-spacing: 0.34px;
           @media(min-width:$screen-xs) and (max-width:$screen-sm) {
                margin:0 0;
                padding:1.5rem 0 0 0;
            }
        }

        p {
            @media(min-width:$screen-xs) and (max-width:$screen-sm) {
                margin:0 0;
            }   
        }
        
    }
}
