.atl {
    background-color:#f6f6f6;
    background-image: url("/img/waves/waves-medblue-2.svg"), url("/img/waves/wave-made-in-atl.png");
    background-size: contain, contain;
    background-position:bottom center, top center;
    background-repeat: no-repeat;
    min-height: 800px;
    @media(min-width:$screen-sm) and (max-width:$screen-lg) {
        min-height: 600px;
    }
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        min-height: 700px;
    }

    .cusom-header {
        position:relative;

        h5 {
            position:absolute;
            right:31%;
            top:9%;
            font-size:34px;
            line-height:28px;

            @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
                right:25%;
                font-size:24.5px;
                line-height:20px;
            }

            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                right:25%;
                font-size:24.5px;
                line-height:20px;
            }
        }

        h3 {
            font-size:90.5px;
            line-height:1.48;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:65.5px;
                line-height:95.5px;
            }
        }
    }

}