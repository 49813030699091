h1,
h2,
h3,
h4,
h5,
h6 {
  color: $bright-blue;
  text-transform: capitalize;
  margin: 0 0;
  font-weight: inherit;

  &.uppercase {
    text-transform: uppercase;
  }

  &.dark-blue {
    color: $dark-blue;
  }

  &.white {
    color: $white;
  }

  &.neon-green {
    color: $neon-green;
  }

  &.red {
    color: $bright-red;
  }

  &.highlight {
    position: relative;
    display: inline-block;
    color: $bright-red;
    margin-bottom: 30px !important;
    z-index: 2;

    &::before {
      content: '';
      background: $highlight;
      padding: 1rem 2rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &.up {
      &::before {
        transform: skew(1deg, 1deg) scale(1.1) rotate(3deg);
      }

    }

    &.down {
      &::before {
        transform: skew(1deg, 1deg) scale(1.1) rotate(-3deg);
      }
    }

    a {
      color: $bright-red;
    }
  }

  //linked titles
  a {
    color: $bright-blue;
    text-decoration: none;
    border-bottom: 0px solid $neon-green;
    //background-image: linear-gradient(180deg,transparent 50%, $dark-blue);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: color .4s ease;

    //&:after {
    //  content: "";
    //  position: absolute;
    //  bottom: 0;
    //  left: 0;
    //  right:0;
    //  top:0;
    //  transform: scaleX(0);
    //  transform-origin: bottom middle;
    //  background: $neon-green;
    //  z-index: -1;
    //  transition: transform 0.3s;
    //}
    &:hover {
      //text-decoration: none;
      //background-size:100% 100%;
      color: darken($neon-green, 10%);
      //background-size:25% 25%;
      //&::after {
      //  transform: scaleX(1);
      //}
    }
  }
}


h3,
h4,
h5,
h6 {
  color: $bright-blue;
  text-transform: capitalize;

  &.dark-blue {
    color: $dark-blue;
  }

  & a {
    color: $neon-green;
    text-decoration: none;
    text-transform: none;
    font-size: inherit;
  }
}

h5,
h6 {
  text-transform: none;
}

h1 {
  @include NunitoRegular;
  font-size: 7.125rem;
  line-height: 5rem;
  letter-spacing: 0.25rem;
  // text-transform:uppercase;
  margin: 0 0;

  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
    font-size: 4.375rem;
  }

  &.cursive {
    font-family: "Courgette", cursive;
    letter-spacing: -0.2825rem;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      font-size: 3.46875rem;
      letter-spacing: -0.17375rem;
    }
  }

  a {
    font-size: 6.25rem;
  }
}

h2 {
  @include NunitoRegular;
  font-size: 3.375rem;
  line-height: 4.375rem;
  letter-spacing: 0.03125rem;
  margin: 0 0;

  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
    font-size: 2.5rem;
  }

  &.cursive {
    font-family: "Courgette", cursive;
    letter-spacing: -0.2825rem;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      letter-spacing: -0.17375rem;
    }
  }

  a {
    font-size: 4rem;
  }
}

h3 {
  @include NunitoMedium;
  font-size: 2.5rem;
  line-height: 2.875rem;
  letter-spacing: 0.088125rem;
  //margin:0 0 2rem 0;
  margin: 0 0;

  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
    font-size: 30px;
    line-height: 40px;
  }

  &.cursive {
    font-family: "Courgette", cursive;
    letter-spacing: -4.52px;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      letter-spacing: -2.78px;
    }
  }

  a {
    font-size: 3rem;
  }
}

h4 {
  @include NunitoMedium;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: 0.0625rem;
  //margin:0 0 1.5rem 0;
  margin: 0 0;

  a {
    font-size: 1.875rem;
    line-height: 2.375rem;
  }
}

h5 {
  @include NunitoRegular;
  font-size: 1.1875rem;
  line-height: 1.55;
  letter-spacing: 0.0375rem;
  margin: 0 0;

  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
    font-size: 1.15rem;
  }

  &.cursive {
    font-family: "Courgette", cursive;
    letter-spacing: -4.52px;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      letter-spacing: -2.78px;
    }
  }

  a {
    font-size: 1.5rem;
  }

  span {
    font-family: "Courgette", cursive;
    font-size: 5.625rem;
    display: block;
    letter-spacing: -0.25rem;
  }
}

h6 {
  @include RobotoCondensedBold;
  display: block;
  font-size: 1.875rem;
  line-height: 2rem;
  margin: 0 0;

  &.cursive {
    font-family: "Courgette", cursive;
    letter-spacing: -4.52px;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      letter-spacing: -2.78px;
    }
  }

  a {
    font-size: 1.3125rem;
  }
}

ul {
  list-style: none;
}

ul:not(.logos) {
  list-style: disc;

  li {
    @include NunitoRegular;
    color: #1c4668;
    font-size: 1rem;
    line-height: 1.75rem;
    padding: 0 0;
    letter-spacing: 0.05rem;

  }

  &.small {

    li {
      font-size: 1rem;
      line-height: 1.625rem;

      @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
        font-size: 0.7rem;
        line-height: 1.625rem;
      }
    }
  }

  &.tiny {

    li {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 5px 0;
    }
  }

}

p {
  a {
    font-size: inherit;
    text-decoration: underline;
  }
}

a {
  font-size: 1.125rem;
  color: inherit;
  text-decoration: none;
  position: relative;
  padding: 3px;


  &.tiny {
    font-size: 0.875rem;
  }

  &.large {
    font-size: 1.25rem;
  }

  &.neon-green {
    color: $neon-green;
  }

  &.white {
    color: $white;
  }

  &.underline {
    text-decoration: underline;
  }

  &.neon-green-underline {
    text-decoration: none;
    border-bottom: 2px solid $neon-green;
  }

  &.add-decoration {
    border-bottom: 2px solid $neon-green;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.mailto {
    text-decoration-color: $neon-green;
  }

  //&:after {
  //  content: "";
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  right:0;
  //  top:0;
  //  transform: scaleY(0);
  //  transform-origin: bottom left;
  //  background: $neon-green;
  //  z-index: -1;
  //  transition: transform 0.3s;
  //}
  &:hover {
    text-decoration: none;
    //&::after {
    //  transform: scaleY(0.5);
    //}
  }

  &:focus {}

  &:link {}

  &:visited {}
}

p {
  @include NunitoRegular;
  font-size: 1.375rem;
  // margin:20px 0;
  line-height: 2.125rem;
  // letter-spacing: 0.03125rem;
  color: $light-blue;

  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
    margin: 0 0 1.5rem 0;
    font-size: 1.0625rem;
    line-height: 1.59375rem;
  }

  &.white {
    color: $white;
  }

  &.large {
    font-size: 1.4375rem;
    line-height: 1.67;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
      // font-size: 1rem;
    }

  }

  &.bold {
    @include NunitoBold;
  }

  &.small {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 0 0 10px 0;
  }

  &.tiny {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0 0 10px 0;
  }

  &.large-margin {
    margin: 0 0 40px 0;
  }

}

.large {
  p {
    font-size: 1.25rem;
    line-height: 2.125rem;

  }
}

//"Labels" in design kit documentation
//Not HTML <label>
b {
  @include RobotoCondensedBold;
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  margin: 0 0 0.5rem 0;


  &.inline {
    display: inline;
  }

  &.large {
    font-size: 1.875rem;
    line-height: 2rem;
  }

  &.tiny {
    font-size: 0.75rem;
    line-height: 0.875rem;

  }

  &.article-tag {
    @include RobotoCondensedRegular;
    font-size: 0.75rem;
    font-weight: 100;
    letter-spacing: 0.125rem;
    line-height: 1.125rem;
  }

  &.metric {
    font-size: 4.375rem;
    line-height: 5rem;
    letter-spacing: -0.125rem;


    sup {
      @include RobotoRegular;

    }

    &.cursive {
      font-family: "Courgette", cursive;
    }
  }

}

small {
  color: $light-blue;
  font-size: 0.875rem;
  display: block;

  //padding:0.5rem 0.813em;
  &.error {
    color: $bright-red;
  }

  &.white {
    color: $white;
  }

  &.copyright {
    color: $bright-blue;
    font-size: 0.75rem;
    @include NunitoRegular;
    letter-spacing: 0.025rem;
  }

}

sup {
  // font-size: 35px;
  // top:-0.75em;
  color: $bright-red;
}

/*******************************
          Alignment Classes
  *******************************/
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

h5+p {
  margin-top: 10px;
}

.no-cap {
  text-transform: none !important;
}