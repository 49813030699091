.clients {
    background-color: #fff;
    padding-bottom: 3rem;
  
        h2 {
            line-height: 2.75rem;
            margin:60px 0 10px 0 ;
            @media (max-width: $screen-sm) {
                margin:30px 0;
              }
        }
    


    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding-bottom: 3rem;
    }

   

    .client {
        list-style: none;
        text-align: center;
        // padding: 2% 0;
        @media (max-width: $screen-sm) {
            // padding: 0% 0;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }

        img {
            max-width: 180px;
            width:100% ;
        }

    }

    .box-drop-shadow {
        box-shadow: -3px 4px 4px -2px $gray;
    }
    .logos {
        max-width: 1160px;
    }
}

#completeClientList {

    height: auto;
    overflow: hidden;
    margin-top: -30px;
    
    
    ul {
        column-count: 3;
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            column-count: 2;
        }
    }

}

//// SAMPLE CLIENTS block on work page
.company-list {
    text-align: center;
    background-color: #173654 !important;
    padding-bottom: 70px;

    ul {
        li {
            color: #FFFFFF;
            list-style: none;
        }
    }
}