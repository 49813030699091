main {
  @media (max-width: $screen-sm-max) {

    margin-top: 70px;
    ;

  }
}


.HomepageHeader {
  background-image: url("/img/heroes/home-hero.jpg");
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 800px;
  background-color: #06233a;
  padding: 150px 25px 10px 25px;

  @media (max-width: $screen-sm-max) {
    background-image: url("/img/heroes/Home-Header-mid.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 800px;
    background-color: #06233a;
    padding: 270px 25px 10px 25px;


  }


  @media (max-width: $screen-sm) {
    background-image: url("/img/heroes/home-header-mobile.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
    background-color: #06233a;
    padding-top: 15px;
    margin-bottom: -1px;
  }




  .h1-wrapper {
    width: 100%;
    padding: 15px 50px;
    margin: 69px auto 0 auto;
    position: relative;


    @media (max-width: $screen-sm) {
      padding: 15px 20px;
    }


    @media (max-width: $screen-sm) {
      position: unset;
    }

    // to control the 2nd line 
    .line-2 {
      text-align: right;
      position: absolute;
      right: 100px;

      @media (max-width: $screen-lg) {
        right: 10px;
      }

      @media (max-width: $screen-md) {
        right: 10px;
      }

      // background-color: black;
      @media (max-width: $screen-sm) {
        position: unset;
      }
    }

    @media (max-width: $screen-sm) {
      padding: 8px;
      width: 347px;
      margin-top: 120px;
    }


    .first-br {
      padding-left: 10px;

      @media (max-width: $screen-sm) {
        display: none
      }
    }

    //  Target Test "in a"
    .text-in_a {
      @media (max-width: $screen-sm) {
        margin-left: 25px;
        vertical-align: top;
      }

    }

    h1 {
      @include NunitoMedium;
      font-size: 440%;
      line-height: 100%;
      letter-spacing: 2.5px;
      color: #fff;

      //  Hack for this breakpoint 
      @media (min-width:1024px) and (max-width:1219px) {
        font-size: 5.6vw;
      }

      @media (max-width: $screen-md) {
        font-size: 6vw;
        line-height: 100%;
        letter-spacing: 0.03em;
        color: #fff;
      }

      @media (max-width: $screen-sm) {
        font-size: 216%;
        line-height: 120%;
        letter-spacing: -0.05em;
        max-width: 375px;
        margin: auto;
        text-align: left;
      }



      //  Target words "Transforming" and "Digital World"
      .Courgette {
        font-family: "Courgette", cursive;
        letter-spacing: -2px;
        font-size: 120%;
        line-height: 78%;

        @media (max-width: $screen-md) {
          letter-spacing: -2px;
          font-size: 100%;
          line-height: 78%;
        }

        @media (max-width: $screen-sm) {
          font-size: 120%;
          line-height: 1em;
        }
      }



      //  Target word "Transforming"
      span.Courgette:nth-child(1) {
        letter-spacing: -2px;
        font-size: 120%;
        line-height: 0.6em;

        @media (max-width: $screen-md) {
          font-size: 120%;
        }


        @media (max-width: $screen-sm) {
          display: block;
        }
      }
    }

  }






  .button {
    @media (max-width: $screen-sm) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}

.SmartNeighborCaseStudy {
  background-color: #022d4d;
}

.InsightsHomepage {
  background: #071d37;
  background-image: url("/img/waves/wave-dkblue-2.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  padding-bottom: 150px;

  @media (max-width: $screen-sm) {
    padding-bottom: 30px;
  }

}

.case-study {
  background-color: $light-gray;
  background-repeat: no-repeat;
  // min-height: 50vh;
  background-color: #06233a;


  .innerContent {
    padding-left: 20px;

    @media (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }

  &.mbs {
    background-color: $extra-dark-blue;
    background-image: url("/img/home-primaryfeature2-gradientwaves-1.svg"), // background-image: url("/img/home-primaryfeature2-gradientwaves-1.svg"),
      url("/img/primaryfeature-mbs-floating.png"), //   url("/img/primaryfeature-mbs-floating.png"),
      url("/img/primaryfeature-mbs-backgroundimage.jpg"); //   url("/img/primaryfeature-mbs-backgroundimage.jpg");
    background-size: contain, auto 700px, cover; // background-size: contain, auto 700px, cover;
    background-position: bottom center, 55vw 96%, top center;
    // background-image: url("/img/home-primaryfeature2-gradientwaves-1.svg"),
    //   url("/img/content/work/mbs-case-study-floating-img.png"),
    //   url("/img/primaryfeature-mbs-backgroundimage.jpg");
    // background-size: contain, auto 700px, cover;
    // background-position: bottom center, 55vw 80%, top center;
    // background-color: $extra-dark-blue;
    // background-image: url("/img/waves/work-mbs-wave.png"),
    //   linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    //   url("/img/heroes/atl-united.gif");
    // // background-size: contain, auto 45vh, 100% 100%;
    // background-size: contain, cover, 100% 100%;
    // background-position: bottom center, center center, center center;
    // min-height: 50vh;


    @media (max-width: $screen-sm-max) {
      background-image: none;
      padding-bottom: 0;
      background-position: bottom center;
      background-size: 100% auto, 50% auto;
      margin-bottom: -1px;
    }

    .innerContent {
      padding-bottom: 200px;


      @media (max-width: $screen-sm-max) {
        text-align: center;
        padding-bottom: 0;
      }


      .logo-mbs {
        max-width: 140px;
      }

    }

    &.work {
      background-color: $extra-dark-blue;
      background-image: url("/img/waves/work-mbs-wave.png"),
        url("/img/content/work/mbs-case-study-floating-img.png"),
        url("/img/primaryfeature-mbs-backgroundimage.jpg");
      // background-size: contain, auto 45vh, 100% 100%;
      background-size: contain, auto 78%, 100% 100%;
      background-position: bottom center, 55vw 96%, center center;
      min-height: 50vh;



      @media (max-width: $screen-sm-max) {
        background-image: none;
        padding-bottom: 10%;
      }

    }

    &.detail {
      background-color: $white;
      background-image: url("/img/waves/mbs-case-study-hero-wave-01.png"), url("/img/heroes/mbs-case-study-hero.png");
      //  background-size: contain, auto 45vh, 100% 100%;
      background-size: contain, cover;
      background-position: center bottom, center center;

      // min-height: 50vh;
      @media (min-width: $screen-xs) and (max-width: $screen-md) {
        background-image: url("/img/waves/mbs-case-study-hero-wave-mb.svg"), url("/img/heroes/mbs-case-study-hero.png");
      }

      h4 {
        @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
          font-size: 1.5rem;
        }
      }

      .hr {
        border-bottom: 3px dashed $white;
        height: 5px;
        width: 40%;
        margin: 0 auto;
      }

      .logo.mbs {
        @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
          display: block;
          margin: auto;
          max-width: 167px;
        }
      }
    }
  }

  &.gng {
    background-color: $extra-dark-blue;
    background-image: url("/img/waves/home-primaryfeature2-gradientwaves-1-sn.svg"),
      url("/img/content/work/gng-case-study-floating-img.png"),
      url("/img/content/work/work-gng-bg.jpg");
    background-size: contain, 450px, cover;
    background-position: bottom center, calc(37% - 110px) 85%, top center;
    min-height: 600px;

    @media (max-width: $screen-sm-max) {
      background-image: none;
      padding-bottom: 10%;
    }

    .innerContent {
      padding-bottom: 100px;

      @media (max-width: $screen-sm-max) {
        text-align: center;
        padding-bottom: 0;
      }
    }
  }

  &.sn-case-study {
    background-image: url("/img/waves/home-primaryfeature2-gradientwaves-1-sn.svg"),
      url("/img/primaryfeature-sn-floating.png"), url("/img/homepage-9b.jpg");
    background-size: 100% auto, auto 100%, 100% 100%;

    background-position: bottom center, 29% -48px, bottom center;
    background-repeat: no-repeat;
    min-height: 600px;
    background-color: $extra-dark-blue;

    //  Hack to fix bg image 
    @media (min-width: 1281px) and (max-width: 1868px) {
      background-position: bottom center, 24% -13px, bottom center;
    }



    .dark-blue-bg-color {
      background-color: #06223a;
    }

    .innerContent {
      padding-bottom: 100px;

      @media (max-width: $screen-sm-max) {
        text-align: center;
        padding-bottom: 0;
      }
    }

    @media (min-width: $screen-md) and (max-width: $screen-lg-min) {
      background-size: bottom center, calc(21vh - 200px) -35px, bottom center;
      background-position: bottom center, calc(18vw - 200px) -3px, bottom center;
      background-repeat: no-repeat;
      min-height: 600px;
    }

    @media (max-width: $screen-sm-max) {
      background-image: none;
      padding-bottom: 16%;
      background-position: bottom center;
      background-size: 100% auto, 70% auto;
      margin-bottom: -1px;
    }

    .logo-mbs {
      width: 140px;
    }
  }

  .mobile-hero-100 {
    width: 100%;
  }
}

.insights {
  // background-color: $extra-dark-blue;


  &.white {
    .cell {
      .insight_box_top {
        background-image: url("/img/article-frame-dots-down-white.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain, calc(100% - 25px);
        // width: 100%;
        max-width: 320px;
        height: 320px;
        margin: auto;
        position: absolute;

        @media (max-width: $screen-sm) {
          width: 200px;
          height: 200px;
        }




      }

      // top
      &:nth-of-type(2n) {
        .insight_box_top {
          background-image: url("/img/article-frame-dots-top-white.png");

        }
      }

      // right
      &:nth-of-type(3n) {
        .insight_box_top {
          background-image: url("/img/article-frame-dots-right-white.png");
        }
      }

      // left
      &:nth-of-type(4n) {
        .insight_box_top {
          background-image: url("/img/article-frame-dots-left-white.png");
        }
      }
    }
  }


  .cell {
    position: relative;
    margin-bottom: 60px;

    @media (max-width: $screen-sm) {
      margin-bottom: 0;
    }


  }

  h5 {
    margin: 0 56px;
    @include NunitoMedium;

    a {
      max-width: 320px;
      margin: auto;
      padding-top: 15px;
      color: #00ffba;
      font-size: 22px;

      @media (max-width: $screen-sm) {
        font-size: 19px;
      }
    }

  }




  .insight_box_wrap {
    width: 320px;
    height: 320px;
    margin: auto;

    @media (max-width: $screen-sm) {
      width: 200px;
      height: 200px;
    }

  }


  .insight_box_top-new {
    // background-image: url("/img/article-frame-dots-down.png");

    width: 320px;
    height: 320px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto;

    @media (max-width: $screen-sm) {
      width: 200px;
      height: 200px;
    }

  }


  // Old Insights code 



  .cell {
    .insight_box_top {
      background-image: url("/img/article-frame-dots-down.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain, calc(100% - 25px);
      // width: 100%;
      width: 320px;
      height: 320px;
      margin: auto;
      position: absolute;

      @media (max-width: $screen-sm) {
        width: 200px;
        height: 200px;
      }




    }

    &:nth-of-type(2n) {
      .insight_box_top {
        background-image: url("/img/article-frame-dots-top.png");
      }
    }

    &:nth-of-type(3n) {
      .insight_box_top {
        background-image: url("/img/article-frame-dots-right.png");
      }
    }

    &:nth-of-type(4n) {
      .insight_box_top {
        background-image: url("/img/article-frame-dots-left.png");
      }
    }
  }

}

.HomePageClientPortfolio {
  margin-bottom: 100px;
}


.dot-divider--Container {
  background-color: $extra-dark-blue;
  background-image: url("/img/divider-page.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  height: 60px;
  margin-bottom: -1px;

  @media (min-width: $screen-md) {
    display: none;
  }
}


.dot-divider {
  width: 100%;
  margin-bottom: 30px;


  @media (min-width: $screen-md) {
    display: none;
  }
}



.InsightsPage {
  .insightsFeed {
    h5 {
      a {
        color: $bright-blue;
      }
    }
  }


}


.logo-hero-container-box {
  padding: 5% 0;
  text-align: center;

  img {
    max-width: 75%;
  }
}

.box-drop-shadow {
  box-shadow: -3px 4px 4px -2px $gray;
}

//  This removes indent on 1st line for headers . move to global styles
h4 a {
  padding: 0;
}