.recognition {
    background-color:$medium-blue;
    background-image:url("../img/waves/wave-award-top.png"), url("../img/waves/wave-award-bottom.png"), url("../img/icons/work/work-detail-quote.svg");
    background-position:top center, bottom center, center center;
    background-size:contain, contain, 50% 50%;
    background-repeat: no-repeat;

    min-height:650px;
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        min-height:unset;
        min-height:350px;
    }
    .award {
        text-align: center;
        p, b {
            font-size:1.375rem;
        }
    }  
}