.x-padding {
    @include padding($x-padding, 0, $x-padding, 0);

    &.mobile {
        @include padding($x-padding / 2, 0, $x-padding / 2, 0); 
    }

    &.top {
        @include padding($x-padding, 0, 0, 0);

        &.mobile {
            @include padding($x-padding / 2, 0, 0, 0);  
        }
    }
    &.bottom {
        @include padding(0, 0, $x-padding, 0);

        &.mobile {
            @include padding(0, 0, $x-padding / 2, 0); 
        }
    }
    &.right {
        @include padding(0, $x-padding, 0, 0);

        &.mobile {
            @include padding(0, $x-padding / 2, 0, 0); 
        }
    }
    &.left {
        @include padding(0, 0, 0, $x-padding);

        &.mobile {
            @include padding(0, 0, 0, $x-padding / 2); 
        }
    }
}

.x-margin {
    @include padding($x-margin, 0, $x-margin, 0);

    &.mobile {
        @include padding($x-margin / 2, 0, $x-margin / 2, 0);
    }

    &.top {
        @include padding($x-margin, 0, 0, 0);

        &.mobile {
            @include padding($x-margin / 2, 0, 0, 0);  
        }
    }
    &.bottom {
        @include padding(0, 0, $x-margin, 0);

        &.mobile {
            @include padding(0, 0, $x-margin / 2, 0); 
        }
    }
    &.right {
        @include padding(0, $x-margin, 0, 0);

        &.mobile {
            @include padding(0, $x-margin / 2, 0, 0); 
        }
    }
    &.left {
        @include padding(0, 0, 0, $x-margin);

        &.mobile {
            @include padding(0, 0, 0, $x-margin / 2); 
        }
    }
}
