.metric-container {
    position: relative;
    

      &::before {
        content: '';
        background:$light-gray url("/img/icons/work/work-metrics-dot-background.svg");
        background-size: 175%;
        padding: 1rem 2rem;
        position: absolute;
        top: 25px;
        left: 60px;
        right: 30px;
        bottom: 0;
        width: 93%;
        z-index: -1;

        @media(max-width: 768px) {
          top: 20px;
          left: 40px;
          right:20px;
          bottom:0px;
        }

        
      }
      &.up {
        &::before {
          transform: skew(1deg, 1deg) scale(1) rotate(2deg);
        }

      }
      &.down {
        &::before {
          transform: skew(1deg, 1deg) scale(1) rotate(-2deg);
        }
      }

    .metric-content {
      display: inline-block;
      padding-left: 21%;

      @media(max-width: 1024px) {
        padding-left: 18%;
      }

      .metric-header {
        float: left;
        width: 100%;
        height:80px;
        border-bottom:4px solid $dark-blue;
        padding:0 1rem 0 0 ;
        position: relative;

        @media(max-width: 1024px) {
          // width: 85%;
        }

        b {
          position: absolute;
          bottom:0;
          left:120px;

          @media(max-width: 640px) {
            font-size: 16px;
            line-height: 18px;
            left:60px;
          }

          &.metric {
            position: absolute;
            bottom:0;
            left:0;
            line-height:50px;

          }
        }

        @media(max-width: 767px) {
          display: inline;
          b.metric {
            font-size: 35px;
            line-height: 30px;
          }
        }
      }
      p.small {
        float: left;
        width: 95%;
        padding: 20px 0 40px 5px;

        @media(max-width: 768px) {
          font-size: 16px;
          line-height:18px;
          width: 90%;
          padding: 20px 20px 40px 5px;
        }
      }
    }
  }