.awards {
    // background-color:$medium-blue;
    // background-image:url("../img/waves/wave-award-top.png"), url("../img/waves/wave-award-bottom.png"), url("../img/icons/work/work-detail-quote.svg");
    // background-position:top center, bottom center, center center;
    // background-size:contain, contain, 50% 50%;
    // background-repeat: no-repeat;
    // background-image: url("../img/icons/work/work-detail-quote.svg");
    // background-position:center center;
    // background-size:60% 60%;
    // background-repeat: no-repeat;
    min-height:650px;
    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        // background-size:contain, contain, 100%;
        min-height:unset;
        min-height:350px;
    }
    .award {
        text-align: center;
        // padding:5% 0;
        // box-shadow: 0 0 2px $gray;
        p, b {
            font-size:1.375rem;
        }
    }  
}