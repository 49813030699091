.case-studies {
    background-color:$extra-dark-blue;
    // background-image: url('/img/waves/waves-xdkblue-medblue-1.svg');
    // background-image: url('/img/waves/waves-medblue-dkblue-1.jpg');
    // background-position: center bottom;
    // background-size:contain;
    // background-repeat: no-repeat;
    padding-bottom:10%;
    // h2 {
    //     color:red;
    //     font-size: 45px;
    // }

    .cell {

        .insight_box {
            background-color: $extra-dark-blue;
            background-position: center;
            background-repeat: no-repeat;
            background-size:contain , calc(100% - 25px);
            width: 100%;
            max-width: 350px;
            height: 350px;  
            margin: auto;
            @media (max-width: $screen-sm) {
                width: 200px;
                height: 200px;
              }

       }

        // &:first-of-type {
        //     .insight_box {
        //         background-image: url('/img/fpo-article-frame.png'), url('/img/content/case-studies/smart-neighbor-case-study.jpg');
        //     }
        // }
        // &:nth-of-type(2) {
        //     .insight_box {
        //         background-image: url('/img/fpo-article-frame.png'), url('/img/content/case-studies/fadv-case-study-img.jpg');
        //     }
        // }
        // &:nth-of-type(3) {
        //     .insight_box {
        //         background-image: url('/img/fpo-article-frame.png'), url('/img/content/case-studies/worldpay-case-study-img.jpg');
        //     }
        // }

        h5  {
        
            text-align: center;
           display: flex;
            height: 75px;
            a {
                @include NunitoMedium;
                width:75%;
                margin:0 auto; 
                max-width: 320px;
                margin: auto;
                font-size: 22px;
          @media (max-width: $screen-sm) {
            font-size: 19px;
          }
        }
    }

        img {
            display:block;
            max-width:50%;
            margin:10px auto;
            height: 55px;
            @media (max-width: $screen-sm) {
                max-width:200px;
            
        }
    }
    .worldpay {
        padding: 9px 0;
    }
    
    }

}