.services {

    .case-studies {
        background-image: none;
        .dot-divider{
            display: none;
        }
    }

    .services-icon {
        display:block;
        margin:10px auto;
    }

    .dashed {
        border-right:2px dashed #6dd6f6;
    }
    h5 {
        font-size:2rem;
        display:block;
        text-align:center;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1.46875rem;
        }
        img.services-icon {
            display:inline;
            margin:0 0.5rem;
            vertical-align: bottom;
            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
                margin:0 0.25rem;
                max-width:15%;
                vertical-align: middle;
            }
        }
    }

    h3 {
        width:75%;
        margin:0 auto;
        padding-top:10px;
        padding-bottom:10px;
      
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
         width:100%;
        }

        &.cursive {
            font-family:"Courgette", cursive;
            // letter-spacing: -6px;
            letter-spacing:-3px;
            font-size:3.5rem;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:2.25rem;
            }
        } 
        sub {
            font-size:4rem;
            bottom:-0.4rem;

            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:3.5rem;
                overflow: auto;
            }
        }
    }

    p {
        // margin:2rem 0;
        width:100%;
        // margin:2rem auto;
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            margin: 0.5rem 0 1.5rem 0;
            font-size: 1.0625rem;
        }
    }

    .services-list {
        // width:50%;
        margin:0 0;
        list-style:none;

        // @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        //     width:80%;
        // }

        li {
            line-height:2; 
            text-indent:5px;
            font-size:0.875rem;
            // @media(min-width:$screen-xs) and (max-width:785px) {
            //     font-size:0.9375rem;
            // }

        }

        &.one {
            li {
                text-align: right;
                @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                    text-align: left;
                }
                &:after {
                    content: "\2014\a0";
                    color:$bright-blue;
                    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                        content: "";
                    }
                }
                @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                    &:before {
                        content: "\2014\a0";
                        color:$bright-blue;
                    }
                }
            }
        }
        &.two {
            margin-top:1rem;
            li {
                text-align: left;
                &:before {
                    content: "\2014\a0";
                    color:$bright-blue;
                }
            } 
        }
    }

}