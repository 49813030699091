.method {
    background-color:$medium-blue;
    background-image: url("/img/waves/waves-medblue-white-1.svg");
    background-size: contain;
    background-position:bottom center;
    background-repeat: no-repeat;
    min-height:900px;

    h2 {
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            margin-top: 30px;
        }
    }

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding-bottom:4rem;
    }

    p {
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1rem;
            line-height:21.5px;
            margin:0 0 0 1rem;
        } 
         
        &.large {
            margin-bottom: 20px;
        }
    }
}