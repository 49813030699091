.button {
  @include RobotoCondensedBold;
    cursor: pointer;
    display: inline-block;
    min-height: 44px;
    min-width: 184px;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: $light-blue;
    letter-spacing:0;
    margin: 0;
    padding: .78571429em 1.5em .78571429em;
    text-transform: uppercase;
    text-shadow: none;
    font-weight: 700;
    line-height: -0.5715em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .14285714rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 $light-blue inset;
    user-select: none;
    transition: opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
    font-size: 19px;
    
    @media (max-width: $screen-sm) {
      font-size: 14.5px;
      line-height: 23px;
      
    }

    &.primary {
        background-color:$neon-green;
        color: $light-blue;
        text-shadow: none;
        background-image: none;
        transition: background-color .4s ease;
        &:hover {
            background-color: darken($neon-green, 5%);
            color: $light-blue;
            text-shadow: none;
        }
    }

    &.disabled {
      cursor:not-allowed;
    }
}

/*******************************
            States
*******************************/

/*--------------
      Hover
---------------*/

// .button:hover {
//     background-color: red;
//     background-image: @hoverBackgroundImage;
//     box-shadow: @hoverBoxShadow;
//     color: @hoverColor;
//   }
  
//   .button:hover .icon {
//     opacity: @iconHoverOpacity;
//   }
  
  /*--------------
        Focus
  ---------------*/
  
//   .button:focus {
//     background-color: @focusBackgroundColor;
//     color: @focusColor;
//     background-image: @focusBackgroundImage !important;
//     box-shadow: @focusBoxShadow !important;
//   }
  
//   .button:focus .icon {
//     opacity: @iconFocusOpacity;
//   }
  
  /*--------------
        Down
  ---------------*/
  
//   .button:active,
//   .active.button:active {
//     background-color: @downBackgroundColor;
//     background-image: @downBackgroundImage;
//     color: @downColor;
//     box-shadow: @downBoxShadow;
//   }
  
  /*--------------
       Active
  ---------------*/
  
//   .active.button {
//     background-color: @activeBackgroundColor;
//     background-image: @activeBackgroundImage;
//     box-shadow: @activeBoxShadow;
//     color: @activeColor;
//   }
//   .active.button:hover {
//     background-color: @activeHoverBackgroundColor;
//     background-image: @activeHoverBackgroundImage;
//     color: @activeHoverColor;
//   }
//   .active.button:active {
//     background-color: @activeBackgroundColor;
//     background-image: @activeBackgroundImage;
//   }