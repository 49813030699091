.headshots {
    background-color: #ffffff;
    // background-image: url("../img/waves/img/waves/waves-gray-1.svg");
    background-image: url("../img/waves/waves-gray-1.svg");
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 100px;

    @media (min-width:$screen-lg) {
        padding-bottom: 165px;
    }

    @media (min-width:$screen-xs) and (max-width:$screen-sm-max) {
        padding-bottom: 50px;
        height: auto;

    }

    .avatar {

        b {
            background-color: $dark-blue;
            color: $white;
            padding: 0.5rem;
            letter-spacing: 2px;

            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
                letter-spacing: 0.5px;
            }
        }

        h5 {
            color: $dark-blue;
            font-size: 16px;

            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size: 12px;
            }
        }

        img {
            transition: opacity 0.1s ease-in-out;

            &.svg {
                opacity: 1;
                transition: all 0.1s ease-in-out;
            }

            &.jpg {
                display: none;
                opacity: 0;
            }
        }


    }

}