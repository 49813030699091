.digital {
    background-color:$light-gray;
    background-image: url(/img/waves/waves-gray-2.svg);
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    min-height:600px;
    // padding-bottom:10rem;
    
    @media(min-width:$screen-xs) and (max-width:$screen-sm) {
        padding-bottom:3rem;
        min-height:560px;
    }

    @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
        min-height: 830px;

    }

    .dashed {
        border-right:2px dashed #6dd6f6;
    }

    h5 {
        font-size:2rem;
        display:block;
        text-align:center;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1.5rem;
        }
        img.services-icon {
            display:inline;
            margin:0 0.5rem;
            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
                margin:0 0.25rem;
            }
        }
        sub {
            font-size:4rem;
            bottom:-0.4rem;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:3.5rem;
            }
             &.cursive {
                font-family:"Courgette", cursive;
                // letter-spacing: -6px;
                letter-spacing:-3px;
                font-size:3.5rem;
                @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                    font-size:2.1875rem;
                }
            } 
        }
    }


    h3 {
        width:75%;
        margin:10px auto;
      
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
         width:100%;
         margin:0 auto;
        }

        &.cursive {
            font-family:"Courgette", cursive;
            // letter-spacing: -6px;
            letter-spacing:-3px;
            font-size:3.5rem;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:2.25rem;
            }
        } 
        sub {
            font-size:4.71875rem;
            bottom:-0.4rem;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:3.5rem;
            }
        }
    }

    p {
        margin:2rem 0;
        width:60%;
        margin:2rem auto;
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            width:100%;
            margin: 0.5rem 0 1.5rem 0;
        }
    }

    .services-list {
        margin:0 0;
        list-style:none;
        // width:60%;
        // @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        //     width:90%;
        // }

        margin:0 0;
        list-style:none;
        // @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        //     width:80%;
        // }

        li {
            line-height:2; 
            text-indent:5px;
        }

        &.one {
            li {
                text-align: right;
                @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                    text-align: left;
                }
                &:after {
                    content: "\2014\a0";
                    color:$bright-blue;
                    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                        content: "";
                    }
                    
                }
                @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                    &:before {
                        content: "\2014\a0";
                        color:$bright-blue;
                    }
                }
            }
        }
        &.two {
            margin-top:1rem;
            li {
                text-align: left;
                &:before {
                    content: "\2014\a0";
                    color:$bright-blue;
                }
            } 
        }

    }
}