
header {
    // width:100%;
  .header {
    // flex-direction: row;
    // justify-content: space-between;
    background-color: $dark-blue;
    border-width: 0 0 3px 0;
    border-style: solid;
    border-image-source: linear-gradient(to right, #5DFE98, transparent, #5DFE98);
    border-image-slice: 10;
    color: $white;
    padding:10px 40px;
    @media (min-width: $screen-xs) and (max-width: $screen-md-max) {
      padding: 20px;
    }

    &.sticky {
      position: fixed;
      //height:90px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      max-width: 100%;
      margin: 0 auto;
    }
    .logo {
      img {
        // width:200px;
        // @media (min-width: 769px) and (max-width: 1024px) {
        //   width:150px
        // }
      }
    }
    .header-inner {
      padding: 20px 50px;
      @media (min-width: 768px) and (max-width: 1024px) {
        padding: 20px 40px;
      }
      //max-width:1440px;
      //margin:auto;
      @media (max-width: $screen-sm) {
        padding: 21px 15px;
      }
    }

    .nav {
      padding-inline-start:0;

      @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
        .flex-item {
          flex-shrink: 2;
        }
      }

      li {
        vertical-align: middle;
        list-style:none;
        padding:10px 20px;
        display: inline-block;
      

        @media (min-width: 1024px) and (max-width: 1145px) {
          padding:10px;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
          padding:0 10px;
        }
        @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
          padding:0 10px;
        }
         //margin: 4px 0 0 140px;
         text-align: right;
         float: left;
         //padding-right: 25px;
         @media (min-width: 1025px) {
           //width: 150px;
         }
         a {
           text-transform: uppercase;
            @include RobotoCondensedRegular;
           font-size: 14px;
           font-weight: bold;
           letter-spacing: 2.15px;
           line-height: 20px;
           color:$white;
           transition:color 0.3s linear;
           .testingLink  {
             color:red !important;
           }
           &:hover {
             color:$neon-green;
           }
           &.active {
            color:$neon-green;
           }
      
         }

      }
    }
    .social-menu {
      padding-inline-start:0;
      li {
        display:inline;
        margin:0 20px;

        @media (min-width: 769px) and (max-width: 1024px) {
          padding:0 10px;
          margin:0 0;
        }

        a {
          i::before {
            color:$white;
            transition:color 0.3s linear;
          }
          &:hover {
            color:$neon-green;
            i:hover::before {
              color:$neon-green;
            }
          }
        }
      }
    }

  }
}

// .header,
// .nav {
//   display: flex;
//   align-items: center;
//   //width: 80%;
//   //margin: 0 auto;
//   //max-width: 1150px;
//   margin-left: auto;
// }


// @media (min-width: 1025px) {
//   .header {
//     flex-direction: row;
//     justify-content: space-between;
//   }
// }

