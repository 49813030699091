@import url('https://fonts.googleapis.com/css?family=Courgette');

/* Vendor */
@import 'settings.scss';
@import 'foundation.scss';
@import 'flickity.scss';

@include foundation-everything;

// @include foundation-global-styles;
// @include foundation-xy-grid-classes;
// @include foundation-visibility-classes;


/* Util */
@import 'util/variables';
@import 'util/mixins';
@import 'util/utils';
@import 'util/animations';
@import 'util/colors.scss';

/* Base */
@import 'base/normalize.scss';
// @import 'base/base.scss';

@import 'base/fonts';
@import 'base/typography';

/* Globals */
@import 'global/footer';
@import 'global/header';
@import 'views/heros.scss';
@import 'global/menu';
// @import 'global/images.scss';

/* Components */
@import 'components/buttons.scss';
@import 'components/forms.scss';
// @import 'components/open-content.scss';
// @import 'components/carousel.scss';
@import 'components/tabs.scss';
@import 'components/slider.scss';

/*Blocks*/
@import 'blocks/blocks';
@import 'blocks/talk.scss';
@import 'blocks/hq.scss';
@import 'blocks/map.scss';
@import 'blocks/talk.scss';
@import 'blocks/wave.scss';
/*Home*/
@import 'blocks/clients.scss';
/*Work*/
@import 'blocks/metric.scss';
@import 'blocks/solution.scss';
@import 'blocks/outcome.scss';
@import 'blocks/testimonials.scss';
@import 'blocks/awards.scss';
/*Services*/
@import 'blocks/services.scss';
@import 'blocks/cx.scss';
@import 'blocks/digital.scss';
@import 'blocks/brands.scss';
@import 'blocks/journey.scss';
@import 'blocks/partners.scss';
@import 'blocks/case-studies.scss';
/*About*/
@import 'blocks/ux.scss';
@import 'blocks/atl.scss';
@import 'blocks/community.scss';
@import 'blocks/theater.scss';
@import 'blocks/method.scss';
@import 'blocks/industries.scss';
@import 'blocks/awards.scss';
/*Careers*/
@import 'blocks/careers.scss';
/*Team*/
@import 'blocks/passionate.scss';
@import 'blocks/collaborative.scss';
@import 'blocks/proud.scss';
@import 'blocks/headshots.scss';
@import 'blocks/proven.scss';
/* View */
@import 'views/views.scss';
/* Case Studies */
@import 'blocks/recognition.scss';