// footer.html


footer {

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // padding:60px 50px;
    @media (max-width: 767px) {
      // padding: 100px 15px;
    }
    // text-align:center;
    margin:auto;
    color:$white;
    border-width:2px  0px 0px 0px;
    border-style:solid;
    border-image-source: linear-gradient(to right,#5dfe98,$dark-blue,#5dfe98);
    border-image-slice: 10;
    max-height:480px;
    margin-bottom :-5px;
    background-color:$dark-blue;
    @media(max-width:$screen-sm) {
      max-height:inherit;
    }
  }

    // sub-footer.html
  .sub-footer-container {
      border-width:2px  0 0 0;
      border-style:solid;
      border-image-source: linear-gradient(to right, #5DFE98, $dark-blue , #5DFE98);
      border-image-slice:10;
      min-height:300px;
      background-color:$dark-blue;
        @media(max-width:$screen-sm) {
            height:auto;
            min-height:unset;
        }
    .container {
      //margin-top: 50px;
      //@media (max-width: 1023px) {
      //  margin-top: 30px;
      //}
      //@media (max-width: 767px) {
      //  margin-top: 15px;
      //}
      .row {
        @media (min-width: 1024px) {
          margin: auto 50px;
        }
      }
    }
  }

  .footer-menu-container {
    padding:3rem 0;
    @media(min-width:$screen-sm) {
      padding:2rem 0 2rem 0;
    }
    .footer-menu {
      -webkit-padding-start:0;
      margin:0 0;
        li {
          list-style:none;
          line-height:20px;
          padding:0 0 15px 0;
           a {
             padding:0 0 6px 0;
             font-size:18px;
             &:hover {
               text-decoration: underline;
             }
           }
          }
        }
    }

.sub-footer-container {
    min-height:238px;
   .social-menu {
     -webkit-padding-start: 0;
     //background-color:red;
     display: block;
    //  padding-top: 80px;
     margin:0 0;
     @media (min-width: $screen-sm) {
      //  padding-top: 80px;
     }
     @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
       padding-top: 0;
     }
     li {
       display: inline;
       @media (max-width: $screen-sm) {

       }
       +li a {
         margin-left: 10px;
       }
       a {
         display: inline-block;
         text-decoration: none;
         background-color: $bright-blue;
         width: 28px;
         height: 28px;
         text-align: right;
         border: 1px solid $bright-blue;
         border-radius: 4px;
         //margin-right: 10px;
         box-sizing: content-box;
         i {
           font-size: 1.5rem;
           &:hover::before {
             color: $neon-green;
           }
           &.mq-twitter {
             position: relative;
             top: 2px;
           }
           &.mq-facebook {
             position: relative;
             top: 5px;
           }
         }
       }
     }
   }
 }

  .sub-footer {
    text-align:center;
    margin:auto;
    color:$white;
    padding:1rem 0;

    @media(min-width:$screen-sm) {
     
    }

    .address-container {
      // text-align:left;
      img {

        @media(max-width:$screen-sm) {
          // margin-bottom:22px;
        }
        @media(max-width:$screen-xs) and (max-width:$screen-xs-max){
          // margin-bottom:12px;
        }

      }


        .email {
          text-decoration:underline;
          }
      p.tiny {
        line-height: 24px;
      }

    }

    .legal-container {
      text-align:left;
      clear:both;

    }
  }
