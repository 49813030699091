//////////////////////////////////////////////////
/////////STYLES for INDIV VIEWS//////////////////
////////////////////////////////////////////////
.home {





}

.testimonials {
  // height:465px;

  .open-content {
    height:500px;
    background-image:url("/images/icons/work-detail-quote.svg");
    background-repeat:no-repeat;
    background-position:center center;
    background-size: 100% 300px;
    padding-top:166px;
    }





  }

.about {  .ux-about {
    width: 100%;
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      padding:25px;
    }
    .container {
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding-left:0px;
        padding-right:0px;
      }
      .open-content {
        margin: 50px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          margin:0;
        }
        p {
          margin: 0 0;
          @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            margin:1rem 0;
          }
        }
      }
    }
  }
  .mq-method {
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
      padding:0 0;
    }
    p {
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding:0 0 1rem 0;
      }
    }
    .method-infographic {
      .col-md-3.col-xs-12, .col-xs-6 {
          padding-left:0!important;
          padding-right:0!important;
          margin-left:-3px;
          margin-right:-3px;
          @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            padding-left:0!important;
            padding-right:0!important;
          }
          // &:first-of-type {
          //   @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          //     p {
          //       margin:40px 0 80px 0;
          //     }
          //   }
          // }
          // &:nth-of-type(2) {
          //   @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          //     p {
          //       margin:0 0 70px 0;
          //     }
          //   }
          // }
          // &:nth-of-type(3) {
          //   @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          //     p {
          //       margin:0 0 70px 0;
          //     }
          //   }
          // }
          // &:nth-of-type(4) {
          //   @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          //     p {
          //       margin:0 0 35px 0;
          //     }
          //   }
          // }
      }

      p {
        padding:1rem;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          font-size:12px;
          line-height:21px;
         
          
        }
        @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
          font-size: 19px;
          line-height: 24px;
          
        }
      }
    }
  }
  .yrs-about {
   
    .metric {
      font-size: 99px;

      &.large {
        font-size: 89px;
      }
    }
    .open-content {
      p {
        padding:1rem 0 3rem 0;
      }
    }
   
      .logos {
       
        list-style: none;
        
        li {
          // height: 120px;
          list-style: none;
          padding:1rem 0;
          img {
            max-width:75%;
            // display:block;
            // margin:0 auto;
            @media (max-width: 768px) {
            
            }
            &.ups {
              max-width:50%;
              max-height:50%;
            }
            &.home-depot {
              max-width:35%;
              max-height:50%;
            }
            &.ibm {
              max-width:60%;
            }
            &.sci-am {
              max-width:50%;
            }
          }
        }
      }
   
  }
  .open-content {
      padding:0 0;
      .container {
        padding:0 0;
      }
      @media (min-width:$screen-xs) and (max-width:$screen-sm) {
        .container-fluid {
          padding:0 0;
        }
      }
      .row {
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          margin: 0 0;
        }
        // div[class^="col-"] {
        //   padding: 0 0;
        // }
      }
      .img-pattern {
        background:url(/images/about-us-theater.jpg);
        background-size:cover;
        height:480px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          height:280px;
        }
        &.community {
          background:url(/images/about-us-community.jpg);
        }
      }
      .custom-header {
        position:relative;
        height:125px;
        margin-top:10px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          margin-top:0px;
        }
        span {
          @include NunitoRegular;
          font-size:32px;
          line-height:34px;
          position:absolute;
          top:15px;
          left:28%;
          @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:18px;
            left:25%;
            top:25%;
          }
        }
        h3 {
          font-family:"Courgette", cursive;
          font-size:90px;
          letter-spacing:-4px;
          position:absolute;
          top:50px;
          left:20%;
          @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:60px;
            left:12%;
          }

        }
        &.theater {
          span {
            left:44%;
          }
          h3 {
            left:28%;
          }
        }
      }
    .oc-content {
      padding:0 3rem;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding:0 25px;
      }

      ul {
        margin: 0 0;
        li {
          padding: 0 0;
        }
      }
    }
    &.awards {
      [class*=block-grid-]>* {
        padding:0 0;
      }
    }
  }
  .history {
    //padding: 50px 0;
    .slider-intro {
      overflow: hidden;
      background: $dark-blue;
      position: relative;
      @media(min-width: 1024px) {
        > div {
          float: right;
        }
        .slide-img {
          width: 66.67%;
          height: auto;
        }
        .history-slide {
          width: 33.33%;
          height: 100%;
          padding-bottom: 999px;
          margin-bottom: -999px;
          position: relative;
        }
      }

      .intro-slide-text {
        margin: 25px;
        @media (min-width: 1280px) {
          padding-top: 30%;
        }
        p {
          margin: 20px 0 50px 0;
        }
      }
    }
    .history-row {
      display:none;
      overflow: hidden;
      background: $dark-blue;
      position: relative;
      >div {
        float:right;
      }
      .history-text {
        @media(min-width: 1024px) {
          float: left;
          width: 33.33%;
          height: 100%;
          padding-bottom: 999px;
          margin-bottom: -999px;
        }
        @media (max-width: 1023px) {
          padding-top: 80px;
        }
        .slide-text {
          display: none;
          /* Fading animation */
          &.fade {
            -webkit-animation-name: fade;
            -webkit-animation-duration: 1.5s;
            animation-name: fade;
            animation-duration: 1.5s;
          }

          @-webkit-keyframes fade {
            from {opacity: .4}
            to {opacity: 1}
          }

          @keyframes fade {
            from {opacity: .4}
            to {opacity: 1}
          }
          &.active {
            display: block;
          }
          .top-nav.white {
            clear: both;
            border-bottom: 2px solid $neon-green;
            height: 32px;
            margin: 15px 50px;
            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
              margin: 0 0 15px 0;
            }
            span {
              color: $white;
              display: inline-block;
              @include RobotoCondensedRegular;
              &:first-of-type {
                text-align: left;
                float: left;
                //padding: 15px;
              }
              &:last-of-type {
                text-align: right;
                float: right;
                font-size: 26px;
                font-weight: 100;
                //padding: 15px;
              }
              a {
                cursor:pointer;
                img {

                }
              }
            }
          }
          .slide-content {
            clear: both;
            text-align: left;
            margin: 15px 50px;
            @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
              margin: 0 0;
            }
            b.large.color.bright-red {
              font-size: 44px;
              line-height: 1;
              letter-spacing: 1.3px;
            }
            p {
              font-size: 16px;
            }
          }
        }
      }
      #myCarousel {
        @media(min-width: 1024px) {
          float: right;
          width: 66.67%;
          height: auto;
        }
        img {
          width: 100%;
          height: auto;
        }
      }

      .arrow-nav {
        width: 15%;
        height: 50px;
        display: block;
        position: absolute;
        bottom: 0px;
        left: 10%;
        &.hidden-lg.hidden-md {
          bottom: -50px;
          left: 50%;
          transform: translateX(-50%);
        }
        .carousel-control {
          color: $neon-green;
          top: unset;
          .left {
            left: 0;
            bottom: 50px;
          }
          .right {
            left: 33%;
            bottom: 50px;
          }
          img.next {
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
          }
        }
      }
    }
  }
  #myCarousel {
    width: 100%;
    //height: 325px;
    .carousel-inner {
      width: 100%;
      height: auto;
    }
  }
}


//Contact

.contact-view {
 

  &.hero1-container {
    background-image: url("/img/contact-hero.jpg");
    background-size: cover;
    background-position: 50% 100%;
    max-height: 490px;
    height: 100%;
    width: 100%;
    margin: 0;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-image-source: linear-gradient(to right,#012842,#5dfe98,#012842);
    border-image-slice: 10;
    @media (max-width: 767px) {
      max-height: 450px;
      h1 {
        font-size: 50px;
      }
      background-image: url("/images/contact-hero-m.jpg");
    }
  }

  .form {
    @media (max-width: 1440px) {
      max-width: 690px;
    }
    @media (min-width: 1025px) {
      margin: 0 0 0 75px;
    }
    @media (max-width: $screen-md) {
      margin: 0 30px;
    }
    @media (max-width: $screen-sm) {
      margin: 0;
    }
  }

  aside {
    margin-top: 3em;
    padding-left: 24px;
    border-left: 1px solid #ccc;
    @media(min-width: $screen-md) {
      &.col-xs-12.col-md-3.col-md-push-1 {
        left: 3%;
      }
    }
    @media(max-width:$screen-md) {
      margin-top:1em;
      border-left:0px solid #ccc;
      margin: 0 30px;
    }
    @media (max-width: $screen-sm) {
      margin: 0;
    }
    ul.get-in-touch {
      margin:0 0;
      -webkit-padding-start:0;
      @media(max-width:767px) {
        -webkit-padding-start:0 !important;
        padding: 0;
      }
      li {
        padding:0 0 20px 0;
        list-style:none;
      }
    }
  }
  button.button.primary {
    margin: 30px auto;
    display: block;
  }
}

.wave-contact {
  background-image: url("/images/waves/gray-medblue-3.svg");
  background-position: left top;
  background-size: cover;
  width: 100%;
  height: 159px;
  background-repeat: no-repeat;
  margin-bottom: -2px;
}

.location {
  // position: relative;
  // max-height: 705px;
  // height: 100%;
  @media (max-width: 1279px) {
    // min-height: 1100px;
  }
  @media (max-width: 1023px) {
    // min-height: 900px;
  .container {
    margin-top: 50px;
    }
  }
  @media (max-width: 767px) {
    //min-height: 600px;
    .container {
      margin: 85px 10px;
    }
  }
  section {
    position: relative;
    @media (max-width: 1023px) {
      //float: left;
    }
    //map
    &.map {
     //max-width: 702px;
     margin: auto;
      @media (max-width: 1023px) {
        //max-width: 500px;
        margin:auto;
      }
      @media (max-width: 767px) {
        //max-width: 325px;

      }
    }
    #map {
      z-index: 1;
      @media (max-width: 1023px) {
        width: 500px !important;
        height: 300px !important;
        margin: auto;
      }
      @media (max-width: 767px) {
        margin: auto;
        width: 325px!important;
        height: 225px!important;
      }
    }
    .map-bg {
      transform: rotate(270deg);
      position: absolute;
      max-width: 510px;
      max-height: 825px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      margin: auto;
      @media (max-width: 1023px) {
        margin: auto;
        left: 0;
        right: 0;
        bottom: -84px;
        width: 300px;
        height: 500px;
      }
      @media (max-width: 767px) {
        max-width: 230px!important;
        max-height: 330px!important;
        margin: auto;
        left: 0;
        right: 0;
        bottom: -35px;
      }
      &.bg-dot:after {
        content: "";
        transform: rotate(6deg);
        background-image: url("/images/dots-blue.svg");
        background-size: 25% 21%;
        @media(max-width: 1023px) {
          background-size: 35% 21%;
        }
        @media (max-width: 767px) {
          background-size: 43% 30%;
        }
        opacity: .4;
        z-index: -4;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
      }
    }
    &.address {
      background: none;
      @media (max-width: 1023px) {
        //max-width: 500px;
      }
      @media (min-width: 1280px) {
        height: 532px;
      }
      .office {
        max-width: 420px;
        width: 100%;
        //position: absolute;
        //top: 50%;
        //bottom: 0;
        //left: 0;
        //right: 0;
        min-height: 322px;
        @media (min-width: 1280px) {
          margin: 100px auto;
        }
        margin: 40px auto;
        @media (min-width: 768px) {
          left: 0;
          right: 0;
          position: absolute;
          margin: auto;
        }
        @media (max-width: 767px) {
          //left: 25%;
          //position: absolute;
        }
        button.button.primary {
          margin-top: 50px;
        }
      }
    }
  }
}



//Team
.team-view {


  .team {
    -webkit-padding-start:0;
    margin:0 auto;

      li {
        display:inline-block;
        float:left;
        background:#efefef;
        margin:10px;
        width:215px;
        height:215px;
        position:relative;
      }

      .head-shot {

        .name {
          position:absolute;
          bottom:10px;
          left:0;
          background:$light-blue;
          color:$white;
          padding:5px 10px;
          width:auto;
        }
      }
  }

}

.team {
  .open-content {
    width: 100%;
    @media (min-width: 1280px) {
      padding: 0 50px;
    }
  }
  .teams-how-we-work {
    div.right, div.left {
      @media ( max-width: 1023px) {
        text-align: center;
      }
    }
  }
}

.services {
  .tab-links li {
    //display: block;
    //clear: both;
  }
  // h3 + p, p + b {
  //   margin-top: 15px;
  //   margin-bottom:40px;
  // }
  // ul:not(.block-grid-xs-2) {
    // width:85%;
    // margin:0 auto;
    // -moz-column-count: 2;
    // -moz-column-gap: 20px;
    // -webkit-column-count: 2;
    // -webkit-column-gap: 20px;
    // column-count: 2;
    // column-gap: 20px;
  
    // &.logos {
    //   list-style: none;
    //   margin: 0;
    //   padding: 0;
    //   li {
    //     @media (max-width: 768px) {
    //       height: 150px;
    //     }
    //   }
    // }
  // }
  // img {
  //   &.services-icon {
  //     display:block;
  //     margin:20px auto;
  //   }
  // }
}


.work {
  .MoreCaseStudies {
    width:500px;
    height:250px;
    margin:auto;
    padding-top:100px;
  }

   #completeClientList {
    height: auto;
    overflow: hidden;
    margin-top: 1em;
    //padding-top: 14px;

    ul {
      margin-top: 0px;
      li {
        line-height:26px;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          line-height:18px;
        }
      }
    }
  }

  #expandCompleteClientList {
    margin-top: 40px;
  }
}

.work-mbs, .work-sn {
  
  .case-study {
      background-color: $white;
      // background-image: url("/img/waves/waves-blue-dotted-white.png");
      // background-size: 100% auto;
      // background-position: bottom center;
      // background-repeat: no-repeat;
      min-height: 50vh;
      
      @media (min-width:$screen-xs) and (max-width:$screen-sm-max) {
        // background-image: url("/img/waves/waves-blue-dotted-white.png"), url("/img/mbs-casestudy-silouette-mb.png");
        // padding-bottom: 40%;
        background-position: bottom center, 33vw 100%;
        background-size: 100% auto, 30% auto;
      }

      &.challenge {
   
          // background-image: url("/img/content/case-studies/opportunity-dots.png");
          // background-size: 30% auto;
          // background-position: 20% center;
          // background-repeat: no-repeat;
     
        h6 {
          font-size:2.09375rem;
          letter-spacing: 0.75px;
          @media(min-width:$screen-xs) and (max-width:$screen-sm) {
            font-size: 1.625rem;
          }
        }

        .dashed {
            border-right:2px dashed #6dd6f6;
            background-color:#6dd6f6;
            width:2px;
        }

        .dots {
          background-image: url("/img/content/case-studies/opportunity-dots.png");
          background-size: 94% auto;
          background-position: 30% center;
          background-repeat: no-repeat;
          padding: 3.5rem 1.25rem;
          @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
              background-size: 40% auto;
              
          }
          @media(min-width:659px) and (max-width:767px) {
            background-size: 40% auto;
              
          }
          @media(min-width:599px) and (max-width:658px) {
            background-size: 50% auto;
              
          }
          @media(min-width:539px) and (max-width:598px) {
            background-size: 65% auto;
              
          }
          @media(min-width:441px) and (max-width:538px) {
              background-size: 80% auto;
              
          }
          @media(min-width:$screen-xs) and (max-width:440px) {
              padding: 3rem 1.25rem;
              background-size: 90% auto;
          }
        }

        .challenge-list {
          // width:50%;
          margin:0 0;
          list-style:none;
  
          // @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          //     width:80%;
          // }
  
          li {
              line-height:2; 
              font-size:1.25rem;
              @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                  font-size:0.75rem;
              }
  
          }
  
          &.single {
              li {
                  text-align: left;
                  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                      text-align: left;
                  }
                  &:before {
                      content: "\2014\a0";
                      color:$bright-blue;
                      @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                          content: "";
                      }
                  }
                  @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                      &:before {
                          content: "\2014\a0";
                          color:$bright-blue;
                      }
                  }
              }
          }
          &.multi {
              margin-top:1rem;
              border-left:2px dashed #6dd6f6;
              li {
                  text-align: left;
                  &:before {
                      content: "\2014\a0";
                      color:$bright-blue;
                  }
              } 
          }
      }

      &.solution {

        h6 {
          font-size:2.09375rem;
          letter-spacing: 0.75px;
        }
      }
       
    }
  }
   

}

.work-sn {
  section .col- * {
    position: relative;
  }
  .challenge {
    &.sn {
      background-color: $medium-blue;
      background-image: url("/images/waves/waves-blue-dotted-white.svg"), url("/images/sn-casestudy-open-content-top.png");
      background-size: 100% auto,600px auto;
      background-repeat: no-repeat;
      //background-position: center bottom, 85% bottom;
      background-position: center calc(100% + 3px), 85% bottom;
      min-height: 500px;
      padding-left: 40px;
      @media (max-width: 1023px) {
        background-position: center bottom, center bottom;
        padding-bottom: 300px;
        padding-right: 20px;
        background-size: 100% auto, 270px auto;
      }
    }
  }
  .outcome {
    padding: 20px;
    margin-top: -1px;
    border-top: 2px $white solid;
    br.text-pad {
      line-height: 5;
    }
    .outcome-block:nth-child(odd) {
      //transform: translateY(20px);
    }
    .row + .row {
      margin-top: 60px;
    }
  }
}

.work-gng {
  section .col- * {
    position: relative;
  }
  .challenge {
    &.gng {
      background-color: $medium-blue;
      background-image: url("/images/waves/waves-blue-dotted-white.svg"), url("/images/gng-casestudy-open-content-top.png");
      background-size: 100% auto,600px auto;
      background-repeat: no-repeat;
      min-height: 500px;
      padding-left: 40px;
      background-position: center calc(100% + 3px),100% 15%;
      @media (max-width: 1023px) {
        //background-position: center bottom,center 92%;
        background-position: center calc(100% + 3px),center 92%;
        padding-bottom: 300px;
        padding-right: 20px;
        background-size: 100% auto,350px auto;
      }
      @media (max-width: 1023px) {
        div {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .outcome {
    padding: 30px;
    margin-top: -1px;
    border-top: 2px $white solid;
    @media (max-width: 1023px) {
      div {
        padding: 0;
        margin: 0;
      }
    }
    @media (min-width: 1024px) {
      padding: 50px;
    }
    br.text-pad {
      line-height: 5;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        line-height:2;
      }
    }
    .outcome-block:nth-child(odd) {
      //transform: translateY(20px);
    }
    .row + .row {
      margin-top: 60px;
    }
  }

}
.work-fadv {
  section .col- * {
    position: relative;
  }
  .challenge {
    &.fadv {
      background-color: $medium-blue;
      background-image: url("/images/waves/waves-blue-dotted-white.svg");
      background-size: 100% auto,600px auto;
      background-repeat: no-repeat;
      //background-position: center bottom, 85% bottom;
      background-position: center calc(100% + 1px),85% bottom;
      min-height: 500px;
      padding-left: 40px;
      @media (max-width: 1023px) {
        //background-position: center bottom, center bottom;
        padding-bottom: 300px;
        padding-right: 20px;
        background-size: 100% auto,350px auto;
      }
      @media (max-width: 1023px) {
        div {
          padding: 0;
          margin: 0;
        }
      }
      img {
        max-height: 450px;
        margin-bottom: 50px;
        @media (max-width: 1023px) {
          display: block;
          float: none;
          margin: 30px auto;
        }
      }
    }
  }
  .outcome {
    padding: 30px;
    margin-top: -1px;
    border-top: 2px $white solid;
    @media (max-width: 1023px) {
      div {
        padding: 0;
        margin: 0;
      }
    }
    @media (min-width: 1024px) {
      padding: 50px;
    }
    br.text-pad {
      line-height: 5;
    }
    .outcome-block:nth-child(odd) {
      //transform: translateY(20px);
    }
    .row + .row {
      margin-top: 60px;
    }
  }

}
.work-world {
  section .col- * {
    position: relative;
  }
  .challenge {
    &.world {
      background-color: $medium-blue;
      background-image: url("/images/worldpay-body-image-1.png"), url("/images/waves/waves-blue-dotted-white.svg");
      background-size: 600px auto, 100% auto;
      background-repeat: no-repeat;
      //background-position: 95% 95px,center bottom;
      background-position: 85% calc(100% + 33px),85% bottom;
      min-height: 500px;
      padding-left: 40px;
      @media (max-width: 1023px) {
        background-position: center bottom,center bottom;
        padding-bottom: 300px;
        padding-right: 20px;
        background-size: 350px auto,100% auto;
        div {
          padding: 0;
          margin: 0;
        }
      }
      @media (max-width: 767px) {
        background-position: center 485px,center bottom;
      }

    }
  }
  .outcome {
    padding: 30px;
    margin-top: -1px;
    border-top: 2px $white solid;
    @media (max-width: 1023px) {
      div {
        padding: 0;
        margin: 0;
      }
    }
    @media (min-width: 1024px) {
      padding: 50px;
    }
    br.text-pad {
      line-height: 5;
    }
    .outcome-block:nth-child(odd) {
      //transform: translateY(20px);
    }
    .row + .row {
      margin-top: 60px;
    }
  }

}

.careers-module {
  //padding-top :85px;
  width: 100%;

  .team-players {
    //margin-bottom: 50px;
  }

  section.body-copy.large.center {
    @media (max-width: 1279px) {
      max-width: 670px;
      margin: 15px auto;
      float: none;
    }
    @media (max-width: 767px) {
      max-width: 300px;
      margin: 15px auto;
      padding: 0;
      float: none;
    }
  }

  .careers {
    padding:0;
      li{
        list-style:none;
        display:inline-block;
        float:left;
        margin:10px;
        @media (min-width: 768px) and (max-width:1024px){
          width:46%;
        }
        @media (max-width: 767px) {
          margin:20px 0px;
          width:100%;
        }
        width:23%;
        height:160px;
        position:relative;
        &.slide-down {
          &::after {
            transform: rotate(-3deg);
            bottom: 0;
            }
          .career {
            @media (min-width: 768px) {
              margin-top: 40px;
            }
          }
        }
        &::after {
          content: '';
          background:url("/images/dots-blue.svg");
          background-size: 50% 100%;
          opacity:0.4;
          display:inline-block;
          width:280px;
          height:160px;
          left: 15px;
          position:absolute;
          bottom:35px;
          transform: rotate(-3deg);


          }
        &.slide-up {
          &::after {
            transform: rotate(3deg);
          }
        }
        &::after {
          content: '';
          background:url("/images/dots-blue.svg");
          background-size: 50% 100%;
          opacity:0.15;
          display:inline-block;
          width:280px;
          height:160px;
          left: 15px;
          position:absolute;
          bottom:35px;
          transform: rotate(3deg);
          @media (max-width: 767px) {
            bottom: 5px;
          }


        }
        .career {
          @media (max-width: 767px) {
            margin-top: 40px;
          }
        }
      }
      .career {

      }
  }
  .button {
    margin: 20px 0 50px;
}

}

.work {


  .logo {

    &.mbs {
      width:190px;
      margin:0 0 40px 0;
    }
  }

}

/**
* OUTCOME BLOCKS NEW
**/

.outcome-grid-container {
  // display: grid;
  // margin: auto;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 300px;
  // grid-column-gap: 40px;

  @media(max-width: 1024px) {
    // grid-template-columns: 100%;
    // grid-template-rows: 1fr 1fr 1fr;
    // grid-row-gap: 30px;
    // margin-top: 20px;

    .outcome-block.block-bottom {
      justify-self: end;
      width: 85%;
      @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        width:100%;
      }
    }

    .outcome-block.block-top {
      justify-self: start;
      width: 85%;
      @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
          width:100%;
      }
    }
  }

  .outcome-block {
    justify-self: center;

    &:first-of-type {
      .outcome-block-content {
        .metric-container {
          .metric-content {
            @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
              padding-left: 15%;
            }
            .metric-header {
              b:not(.metric) {
                // left: 80px;
                @media (min-width: $screen-xs) and (max-width: $screen-xs-max) {
                  left: 40px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-of-type(3) {
      .outcome-block-content {
        .metric-container {
          .metric-content {
            display: inline-block;
            padding-left: 20%;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
              padding-left: 15%;
            }
          }
        }
      }
    }
  }

  .block-bottom {
    align-self: end;
  }

  .block-top {
    align-self: start;
  }

  .outcome-block-content {
    &:hover {
      transform:scale(1.25);
    }
    .icon {
      position: absolute;
      margin-left: 10px;
      padding: 2rem 0 6rem 0;
      width: 18%;

      @media(max-width: 1024px) {
        padding: 25px 0;
        margin-bottom: 50px;
        margin-left: 0;
        width: 20%;
      }

      @media(max-width: 768px) {
        img {
          width:70%;
        }
      }
    }

    // .metric-container {
    //   position: relative;
      

    //     &::before {
    //       content: '';
    //       background:$light-gray url("/images/work-metrics-dot-background.svg");
    //       background-size: 125%;
    //       padding: 1rem 2rem;
    //       position: absolute;
    //       top: 25px;
    //       left: 60px;
    //       right: 30px;
    //       bottom: 0;
    //       width: 93%;
    //       z-index: -1;

    //       @media(max-width: 768px) {
    //         top: 20px;
    //         left: 40px;
    //         right:20px;
    //         bottom:0px;
    //       }

          
    //     }
    //     &.up {
    //       &::before {
    //         transform: skew(1deg, 1deg) scale(1) rotate(2deg);
    //       }

    //     }
    //     &.down {
    //       &::before {
    //         transform: skew(1deg, 1deg) scale(1) rotate(-2deg);
    //       }
    //     }

    //   .metric-content {
    //     display: inline-block;
    //     padding-left: 21%;

    //     @media(max-width: 1024px) {
    //       padding-left: 18%;
    //     }

    //     .metric-header {
    //       float: left;
    //       width: 100%;
    //       height:80px;
    //       border-bottom:4px solid $dark-blue;
    //       padding:0 1rem 0 0 ;
    //       position: relative;

    //       @media(max-width: 1024px) {
    //         // width: 85%;
    //       }

    //       b {
    //         position: absolute;
    //         bottom:0;
    //         left:120px;

    //         @media(max-width: 640px) {
    //           font-size: 16px;
    //           line-height: 18px;
    //           left:60px;
    //         }

    //         &.metric {
    //           position: absolute;
    //           bottom:0;
    //           left:0;
    //           line-height:50px;

    //         }
    //       }

    //       @media(max-width: 767px) {
    //         display: inline;
    //         b.metric {
    //           font-size: 35px;
    //           line-height: 30px;
    //         }
    //       }
    //     }
    //     p.small {
    //       float: left;
    //       width: 95%;
    //       padding: 20px 0 40px 5px;

    //       @media(max-width: 768px) {
    //         font-size: 16px;
    //         line-height:18px;
    //         width: 90%;
    //         padding: 20px 20px 40px 5px;
    //       }
    //     }
    //   }
    // }
  }
}

.outcome-grid-2-col {
  grid-template-columns: 1fr 1fr;
}

/*
.outcome {
  &.outcome {
    width: fit-content;
    margin: auto;
  }
  .outcome-block {

    .icon {
      float:left;
      padding:2rem 0 6rem 0;
      width: 15%;

      @media(max-width: 768px) {
        padding:0 0;
        margin-bottom: 50px;
      }

      @media(max-width: 768px) {
        img {
          width:70%;
        }
      }
    }

    .metric-container {
      position:relative;
        &::before {
          content: '';
          background:@light-gray url("/images/work-metrics-dot-background.svg");
          background-size: 80% 100%;
          padding: 1rem 2rem;
          position: absolute;
          top: 30px;
          left: 60px;
          right: 30px;
          bottom: 0;
          width: 90%;
          min-height: 11em;
          z-index: -1;

          @media(max-width: 768px) {
            top: 30px;
            left: 40px;
            right:40px;
            bottom:0px;
            min-height: 4em;
          }
        }
        &.up {
          &::before {
            transform: skew(1deg, 1deg) scale(1) rotate(2deg);
          }

        }
        &.down {
          &::before {
            transform: skew(1deg, 1deg) scale(1) rotate(-2deg);
          }
        }

      .metric-header {
        display:inline-block;
        border-bottom:4px solid @dark-blue;
        padding:0 1rem 0 0 ;
        width: 85%;
        @media(max-width: 767px) {
          display:inline;
        }
        b.metric {
        }
      }
      p.small {

        width: 85%;
        @media(max-width: 767px) {
          font-size: 10.5px;
          line-height:13.3px;

        }
      }
    }
  }
}
*/

.open-content .row {
  @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    margin-left: 25px;
  }
}


.insights {
  .col-* {
    position: relative;
  }
  &.hero1-container {
    background-image: url("/images/insights-hero.jpg");
    background-size: cover;
    background-position: 70% 90%;
    max-height: 490px;
    height: 100%;
    margin: 0 0 80px 0;
    @media (max-width: 767px) {
      h1 {
        font-size: 50px;
      }
      background-image: url("/images/insights-hero-m.jpg");
    }
  }
  &.page-content {
    .bot-sub-container {
      min-height: 350px;
      height: auto;
      height: initial;
      position: relative;
      margin: auto;
      text-align: center;
      //width: 360px;
      //@media (max-width: 1023px) {
      //  width: 300px;
      //}
      //@media (max-width: 767px) {
      //  width: 220px;
      //}
    }
    .item-text {
      z-index: 3;
      text-align: left;
      margin: 40px auto 60px auto;
      max-width: 360px;
      @media (max-width: 767px) {
        text-align: center;
      }
      .item-title a.bright-blue.color {
        font-weight: 600;
        font-size: 18.2px;
        line-height: 28px;
        letter-spacing: .5px;
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      .item-author {
        color: #fc5661;
        font-family: "RobotoCondensedRegular";
        font-size: 16px;
        font-weight: bold;
        line-height: 26px;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    //.bot-sub {
    //  transform: none;
    //  z-index: 3;
    //  position: relative;
    //  display: inline-block;
    //  margin: 2px 2px 10px 2px;
    //  padding: 25px;
    //  @media (max-width: 1023px) {
    //    width: 300px;
    //    height: 300px;
    //  }
    //  @media (max-width: 767px) {
    //    width: 220px;
    //    height: 220px;
    //  }
    //}
    .bot {
      margin-bottom: 0;
    //  //background: none;
    //  border: 0;
    //  z-index: -3;
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //  //max-height: 480px;
    //  max-width: 360px;
    //  @media (max-width: 1023px) {
    //    max-width: 220px;
    //  }
      &:after {
        background-image: url("/images/patterns/dots-gray.svg");
        background-size: 50% 50%;
    //    opacity: 0.9;
    //    z-index: -4;
    //    width: 360px;
    //    height: 360px;
    //    display: block;
    //    //margin-bottom: 25px;
    //    position: absolute;
    //    @media (max-width: 1023px) {
    //      width: 300px;
    //      height: 300px;
    //    }
    //    @media (max-width: 767px) {
    //      width: 220px;
    //      height: 220px;
    //    }
      }
    //  &.border-c:after {
    //    right: -30px;
    //    bottom: 0px;
    //    @media (max-width: 1023px) {
    //      right: -20px;
    //      bottom: -85px;
    //    }
    //    @media (max-width: 767px) {
    //      right: -15px;
    //      bottom: -5px;
    //    }
    //  }
    //  &.border-u:after {
    //    bottom: -5px;
    //    right: 25px;
    //    @media (max-width: 1023px) {
    //      right: -15px;
    //      bottom: 0px;
    //    }
    //    @media (max-width: 767px) {
    //      right: 15px;
    //      bottom: -10px;
    //    }
    //  }
    }
    .opt-in {
      max-width: 360px;
      width: 100%;
      max-height: 480px;
      height: 100%;
      display: block;
      padding-top: 40px;
      position: relative;
      margin: 0 auto;
      @media (max-width: 767px) {
        margin-top: 84px;
      }
      .bot {
        background: none;
        border: 0;
        position: absolute;
        left: 0;
        top: 30px;
        z-index: -3;
        &:after {
          background-size: 43% 52%;
          background-repeat: repeat;
          opacity: .6;
          width: 480px;
          height: 400px;
          position: absolute;
          top: -50px;
          left: -32px;
          transform: rotate(3deg);
          @media (max-width: 1023px) {
            background-size: 45% 60%;
            height: 360px;
            top: -55px;
            left: -170px;
          }
          @media (max-width: 767px) {
            background-size: 37% 59%;
            bottom: 0;
            right: 0;
            height: 300px;
          }
        }
      }
      i.insights-brain:before {
        content: url("/images/icons/insights-brain.svg");
        position: absolute;
        top: -105px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      h5 {
        text-transform: uppercase;
        @include RobotoCondensedRegular;
        font-size: 28px;
        letter-spacing: 0.8px;
        text-align: center;
        @media (max-width: $screen-xs-max) {
          font-size: 20px;
          line-height: 26px;
        }
      }
      p.small {
        max-width: 300px;
        margin: 0 auto;
        color: #1c4668;
        @include NunitoRegular;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: .6px;
        text-align: center;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      fieldset.options {
        padding-top: 0;
      }
      span {
        max-width: 300px;
        max-height: 74px;
        display: block;
        margin: 0 auto;
        .input .field.custom {
          font-family: "Roboto Condensed";
          font-size: 16px;
          letter-spacing: .5px;
          line-height: 24px;
          height: 60px;
          max-width: 300px;
        }
      }
      button.subscribe.button.primary {
        margin: 30px auto;
        display: block;
      }
    }
    }
  }






/* Shared components */

.case-studies {//used by multiple work detail pages
  // min-height: 750px;
  // padding-top: 50px;
  // overflow: hidden;
  h3 {
    margin-bottom: 50px;
  }
  img {
    margin: auto;
    &.logo-white {
      position: absolute;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
      top: 350px;
      height: 36px;
      width: auto;
      @media (max-width: 1023px) {
      top: 335px;
    }
      @media (max-width: 767px) {
        top: 240px;
      }
    }
  }
  h5 {
    // display: inline-block;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // top: 400px;
    // width: 90%;
    // text-align: center;
    // @media (max-width: 1023px) {
    //   top: 385px;
    //   max-width: 250px;
    // }
    // @media (max-width: 767px) {
    //   top: 285px;
    // }
  }
  .bot-sub-container {
    min-height: 500px;
    position: relative;
    @media (max-width: 1023px) {
      min-height: 585px;
    }
    .bot-sub {
      //margin-left: 54px;
      left: 2px;
      @media (max-width: 1023px) {
        margin-left: 30px;
        position: absolute;
        left: 0px;
      }
    }
    .bot {
      position: absolute;
      left: 2px;
      top: 1px;
      @media (max-width: 1023px) {
      //margin-left: 29px;
      left: 29px;
    }
      @media (width: 768px) {
        //margin-left: 29px;
        left: -2px;
      }
    }
  }
}
.thinking {//used by multiple views
  padding: 80px 0 100px 0;
  @media (max-width: 1023px) {
    padding: 50px 0 60px 0;
  }
}
#myCarousel {//used by multiple views
  margin: auto;
  @media (min-width: 1280px) {
    width: 1200px;
  }
  @media (max-width: 1279px) {
    width: 1000px;
  }
  @media (max-width: 1023px) {
    width: 900px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 750px;
  }
  @media (max-width: 767px) {
    width: 300px;
  }
  .carousel-inner {
    z-index: 3;
    margin: auto;
    width: 1000px;
    @media (max-width: 1279px) {
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 550px;
      height: auto;
    }
    @media (max-width: 767px) {
      height: 120px !important;
      width: 250px !important;
    }
  }
  .carousel-control.right {
    left: auto;
    @media (max-width: 767px) {
      right: -5px;
    }
  }
  .carousel-control.left {
    @media (max-width: 1023px) {
      left: 0;
    }
    @media (max-width: 767px) {
      left: -5px;
    }
  }

  .carousel-indicators {
    @media (min-width: 768px) {
      bottom: -90px;
    }
    @media (max-width: 767px) {
      bottom: -50px;
    }
    left: 50%;
    right: 50%;
    li {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 1px;
      text-indent: -999px;
      border: 1px solid $gray;
      border-radius: 10px;
      cursor: pointer;
      background-color: $gray;
      &.active {
        margin: 0;
        width: 12px;
        height: 12px;
        background-color: $bright-blue;
      }
    }
  }
}

img {
  &.right {
    float: right;
    margin: 0 0 50px 50px;
    @media (max-width: 1023px) {
      margin: 0 0 30px 30px;
    }
  }

  &.left {
    float: left;
    margin: 0 50px 50px 0;
    @media (max-width: 1024px) {
      margin: 0 30px 30px 0;
    }
  }
  &.right, &.left {
    @media (max-width: 1024px) {
      float: none;
      display: block;
      text-align: center;
      margin: 30px auto;
    }
    @media (max-width: 767px) {
      margin: 15px auto;
    }
  }
}

.blog-detail {

  h1 {
    font-size:50px;
    line-height:4rem;
  }
  h5 {
    color:$extra-dark-blue;
  }
  .categories, .share {
    list-style:none;
    margin:0 0;
    li {
      display:inline;
    }
  }
  b {
    margin:0 0;
  }
  .featured-image {
    padding:2rem 0;
  }
}


.img-right {
  float:left;
}
.img-left {
  float:right;
}
@media (max-width: 1023px) {
  .img-left, .img-right {
    float: none;
  }
}
