.talk {
    background-color:$dark-blue;
    background-image: url("/img/global-contact-backgroundimage-ear.jpg");
	background-size: cover;
	background-position:center center;
    background-repeat: no-repeat;
    min-height:42vh;
    @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        min-height:333px;
    }
    h2 {
        padding-bottom:0.5rem;
        letter-spacing: -0.03em;
        // margin:10px 0 ;
    }
    h5 {
        padding-bottom:1.5rem;
        font-size: 22px;
        line-height: 28px;
        @media (max-width: $screen-sm) {
            font-size:19px;
          }



    }
}