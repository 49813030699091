label {
    @include NunitoRegular;
    font-size:18px;
}

///*--------------------
//    Standard Inputs
//---------------------*/

form textarea,
form input:not([type]),
form input[type="date"],
form input[type="datetime-local"],
form input[type="email"],
form input[type="number"],
form input[type="password"],
form input[type="search"],
form input[type="tel"],
form input[type="time"],
form input[type="text"],
form input[type="file"],
form input[type="url"] {
  // width: $inputWidth;
  vertical-align: top;
  background-color:$gray;
  border:none;
  box-shadow:none;
}

fieldset {
  border:0;
  margin:0 0;
  padding:0 0;
}
textarea {
  min-height:250px;
}

///////////////////////////////////////////////
//////////////////////////////////////////////
///////// CUSTOM INPUTS //////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
.input, .textarea {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: .25em 0 0 0;
    //max-width: 350px;
    //width: calc(100% - 2em);
    width: 100%;
    vertical-align: top;
    
    &.custom {
      overflow: hidden;
    }
  
    .field {
      position: relative;
      display: block;
      float: right;
      padding: 0.8em;
      width: 60%;
      border: none;
      border-radius: 0;
      //background: #f0f0f0;
      color: $light-blue;
      -webkit-appearance: none; /* for box shadows to show on iOS */
      &:focus {
        outline: none;
      }
      &.custom {
        margin-top: 1em;
        //padding: 1.25em 0.813em;
        padding: 1.25em .813em .3em .65em;
        width: 100%;
        @include RobotoCondensedRegular;
        background: $white;
        color: $light-blue;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 30px;
      }
    }
  
    .label {
      @include RobotoCondensedRegular;
      display: inline-block;
      float: right;
      padding: 0 1em;
      width: 40%;
      color: $light-blue;
      //font-weight: 500;
      font-size: 70.25%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &.custom {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 0;
        width: 100%;
        height: calc(100% - 1em);
        text-align: left;
        pointer-events: none;
        &.bright-blue::after {
          //border-color: hsl(200, 100%, 50%);
          border-color: $bright-blue;
        }
  
        .bright-red::after {
          //border-color: hsl(160, 100%, 50%);
          border-color: $bright-red;
        }
        &::before, &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 0px);
          border-bottom: 2px solid $light-blue;
        }
        &::after {
          margin-top: 2px;
          border-bottom: 4px solid red;
          -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
          -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
        }
      }
  
      .content {
        position: relative;
        display: block;
        //padding: 1.6em 0.813em;
        padding: 1.5em .813em;
        width: 100%;
        &.custom {
          position: absolute;
        }
      }
    }
  }
  
  
  
  .field.custom:focus + .label.custom::after,
  .filled .label.custom::after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color:$bright-blue;
  }
  
  .field.custom:focus + .label.custom .content.custom,
  .filled .content.custom {
    -webkit-animation: input-anim  0.3s forwards;
    animation: input-anim  0.3s forwards;
    color:$bright-blue;
    font-size:14px;
    line-height: 0;
  }
  
  @-webkit-keyframes input-anim {
    50% {
      opacity: 0;
      -webkit-transform: translate3d(1em, 0, 0);
      transform: translate3d(1em, 0, 0);
    }
    51% {
      opacity: 0;
      -webkit-transform: translate3d(-1em, -10%, 0);
      transform: translate3d(-1em, -10%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, -10%, 0);
      transform: translate3d(0, -10%, 0);
    }
  }
  
  @keyframes input-anim  {
    50% {
      opacity: 0;
      -webkit-transform: translate3d(1em, 0, 0);
      transform: translate3d(1em, 0, 0);
    }
    51% {
      opacity: 0;
      -webkit-transform: translate3d(-1em, -10%, 0);
      transform: translate3d(-1em, -10%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, -10%, 0);
      transform: translate3d(0, -10%, 0);
    }
  }
  
  ///////////////////////////////////
  ///////////////////////////////////
  /////////// Text Area /////////////
  //////////////////////////////////
  ///////////////////////////////////
  
  .textarea {
    width: 100% !important;
    textarea {
      min-height:250px;
    }
  }
  
  ///////////////////////////////////////////////
  //////////////////////////////////////////////
  ///////////////// SELECTS ////////////////////
  ///////////////////////////////////////////////
  //////////////////////////////////////////////
  
  /* Default custom select styles */
  .mq-select {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: left;
    background: $white;
    border-bottom:2px solid $light-blue;
    z-index: 100;
    width: 100%;
    height:50px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    &:focus {
      outline: none; /* For better accessibility add a style for this in your skin */
    }
  
    &.mq-active {
      z-index: 200;
  
      .mq-options {
        visibility: visible;
      }
    }
  
    &.mq-skin-border {
      @media screen and (max-width: 30em) {
        font-size: 1em;
      }
  
      &.mq-active {
  
        > span {
          background: $white;
          border-color: $bright-blue;
          color: $bright-blue;
  
          &::after {
            -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
  
      > span {
        border:none;
        border-bottom: 2px solid $dark-blue;
        border-color: inherit;
        -webkit-transition: background 0.2s, border-color 0.2s;
        transition: background 0.2s, border-color 0.2s;
      }
    }
  
    span {
      @include RobotoCondensedRegular;
      font-size:18px;
      display: block;
      position: relative;
      height:inherit;
      cursor: pointer;
      padding: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    > span {
      padding-right: 3em;
  
      &::after {
        content: '\e909';
        right: 1em;
        speak: none;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
  
    }
  
    select {
      display: none;
    }
    /* Options */
    .mq-options {
      position: absolute;
      overflow: hidden;
      width: 100%;
      background: $white;
      visibility: hidden;
      margin-top:-3rem;
      box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
  
        li {
          padding: 0 0;
  
          span {
            padding: 1em;
  
            &:hover {
              background: $light-gray;
            }
          }
        }
      }
    }
  }
  
  
  //.mq-select > span::after,
  //.mq-select .mq-selected span::after {
  //  speak: none;
  //  position: absolute;
  //  top: 50%;
  //  -webkit-transform: translateY(-50%);
  //  transform: translateY(-50%);
  //  -webkit-font-smoothing: antialiased;
  //  -moz-osx-font-smoothing: grayscale;
  //}
  
  //.mq-select > span::after {
  //  content: '\e900';
  //  right: 1em;
  //}
  
  //.mq-select .mq-selected span::after {
  //  content: '\e900';
  //  margin-left: 1em;
  //}
  
  //.mq-select.mq-active > span::after {
  //  -webkit-transform: translateY(-50%) rotate(180deg);
  //  transform: translateY(-50%) rotate(180deg);
  //}
  
  
  .mq-select ul li.mq-focus span {
    background-color: #ddd;
  }
  
  /* Optgroup and optgroup label */
  .mq-select li.mq-optgroup ul {
    padding-left: 1em;
  }
  
  .mq-select li.mq-optgroup > span {
    cursor: default;
  }
  
  
  //.mq-skin-border {
  //  background: transparent;
  //}
  
  //@media screen and (max-width: 30em) {
  //  .mq-skin-border { font-size: 1em; }
  //}
  //
  //.mq-skin-border > span {
  //  border:none;
  //  border-bottom: 2px solid $dark-blue;
  //  border-color: inherit;
  //  -webkit-transition: background 0.2s, border-color 0.2s;
  //  transition: background 0.2s, border-color 0.2s;
  //}
  
  .mq-skin-border > span::after,
  .mq-skin-border .mq-selected span::after {
    font-family: 'mq';
    content: '\e909';
    font-size:12px;
  }
  
  .mq-skin-border ul span::after {
    content: '';
    opacity: 0;
  }
  
  .mq-skin-border .mq-selected span::after {
    content: '\e909';
    color: #ddd9c9;
    font-size: 1.5em;
    opacity: 0;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  //.mq-skin-border.mq-active > span {
  //  background: #fff;
  //  border-color: #fff;
  //  color: #2980b9;
  //}
  
  .mq-skin-border .mq-options {
    color: #2980b9;
    font-size: 0.75em;
    opacity: 0;
    -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
    transition: opacity 0.2s, visibility 0s 0.2s;
  }
  
  .mq-skin-border.mq-active .mq-options {
    opacity: 1;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  .mq-skin-border ul span {
    padding: 1em 2em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .mq-skin-border .mq-options li span:hover,
  .mq-skin-border li.mq-focus span {
    background: #f5f3ec;
  }
  
  
  ///////////////////////////////////////////////
  //////////////////////////////////////////////
  ///////// CUSTOM RADIOS & CHECKBOXES //////////
  ///////////////////////////////////////////////
  //////////////////////////////////////////////
  [type="checkbox"],
  [type="radio"] {
    @include vh;
  
    &:focus {
  
      + label {
  
        &::before {
          box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) !important;
        }
      }
    }
  
    &:hover {
  
      + label {
  
        &::before {
          border-color: $mq-check-focus-border-color;
        }
      }
    }
  
    &:active {
  
      + label {
  
        &::before {
          transition-duration: 0;
        }
      }
    }
  
    + label {
      background:$white;
      position: relative;
      padding: 0 $mq-input-padding;
      user-select: none;
      line-height:3;
      color: $light-blue;
      font-size: 18px;
      letter-spacing: 0.5px;
      @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
        font-size: 16px;
      }
      //line-height: 30px;
  
      &.block {
        display:block;
      }
  
      &.inline {
        // padding:1rem;
        margin-right:1rem;
          &:last-of-type {
            margin-right:0;
          }
  
        &::after {
          // left: $mq-input-padding + $mq-check-border-width + $mq-check-scale + 6;
          left: 12;
        }
      }
  
      &::before {
        background-color: $mq-check-input-bg-color;
        border: $mq-check-border-width solid $mq-check-border-color;
        box-sizing: content-box;
        content: '';
        color: $mq-check-border-color;
        margin-right: $mq-check-width * 0.25;
        top: 50%;
        left: 0;
        width: $mq-check-width;
        height: $mq-check-height;
        display: inline-block;
        vertical-align: middle;
      }
  
      &::after {
        box-sizing: content-box;
        content: '';
        background-color: $mq-check-border-color;
        position: absolute;
        top: 50%;
        left: $mq-input-padding + $mq-check-border-width + $mq-check-scale/2;
        width: $mq-check-width - $mq-check-scale;
        height: $mq-check-height - $mq-check-scale;
        margin-top: ($mq-check-height - $mq-check-scale)/-2;
        transform: scale(0);
        transform-origin: 50%;
        transition: transform 200ms ease-out;
      }
    }
  
    &[disabled] {
  
      + label {
  
        &::before {
          animation: none;
          box-shadow: none;
          border: 1px solid rgba(128, 128, 128, 0.5);
        }
      }
  
      &:active,
      &:focus,
      &:hover {
  
        + label {
  
          &::before {
            border-color: rgba(128, 128, 128, 0.5);
            filter: none;
            transition: none;
          }
        }
      }
    }
  }
  
  [type="checkbox"] {
  
    + label {
  
      &::before,
      &::after {
      //$if ($mq-check-border-radius) {
        border-radius: $mq-check-border-radius;
      //}
      //$else {
      //  border-radius: 0;
      //}
      }
      &::before {
        background-color: $white;
        border: $mq-check-border-width solid $mq-check-input-bg-color;
      }
  
      &::after {
        background-color: transparent;
        top: 50%;
        @media (max-width: 767px) {
          //top: 30%;
        }
        // left: calc(${mq-input-padding} + ${mq-check-border-width} + ${mq-check-width}/5);
        width: $mq-check-width/2;
        height: $mq-check-width/5;
        // margin-top: calc(${mq-check-height} / -2 / 2 * 0.8);
        border-style: solid;
        border-color: $mq-check-border-color;
        border-width: 0 0 3px 3px;
        border-radius: 0;
        border-image: none;
        transform: rotate(-45deg) scale(0);
        transition: none;
      }
    }
  
    &:checked {
  
      + label {
  
        &::before {
          background-color: $mq-check-input-bg-color;
        }
  
        &::after {
          content: '';
          transform: rotate(-45deg) scale(1);
          transition: transform 200ms ease-out;
        }
      }
    }
  }
  
  [type="radio"] {
  
    + label {
  
      &::before,
      &::after {
      //$if ($mq-check-border-radius) {
        border-radius: 50%;
      //}
      //$else {
      //  border-radius: 50%;
      //}
      }
  
      &::before {
        background-color: $white;
        border: $mq-check-border-width solid $gray;
        color: $mq-check-input-bg-color;
      }
  
      &::after {
        background-color: $mq-check-input-bg-color;
      }
    }
  
    &:checked {
  
      &:active,
      &:focus {
  
        + label {
  
          &::before {
            animation: none;
            filter: none;
            transition: none;
          }
        }
      }
  
      + label {
  
        &::before {
          animation: none;
          background-color: $white;
          border: 2px solid #012842;
        }
  
        &::after {
          margin-top:-9px;
          transform: scale(0.85);
        }
      }
    }
  }
  
  //required
  span.required {
    @include NunitoRegular;
    font-size: 1.25em;
    color: $bright-red;
    vertical-align: top;
  }
  
  //input group label
  .input-group-label {
    color: $dark-blue;
    @include RobotoCondensedBold;
    padding-bottom: 20px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.51px;
    line-height: 20px;
    text-transform: uppercase;
    @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
      font-size: 17px;
    }
    .required {
      line-height: 1.2;
    }
  }
  
  //top required key
  fieldset>.input>span.label {
    text-align: right;
  }
  
  @keyframes borderscale {
    50% {
      box-shadow: 0 0 0 2px #900;
    }
  }
  
  ////////////////////////////////////
  ///////////////////////////////////
  ////// Validation Display ///////
  //////////////////////////////////
  ///////////////////////////////////
  form {
  
    //underline red
    input.error+label.error+label:before, input.error+label.error+.label.custom.bright-blue:after {
      border-bottom: 2px solid $bright-red !important;
    }
    //space for the error message
    input.error+label.error+label, input.error+label.error+.label.custom.bright-blue {
      margin-bottom: 20px;
    }
    //adjust the display
    .input input.error+.error+.label .content {
      margin-top: 20px;
    }
    //text red
    .error {
      color: $bright-red;
    }
    //radio group errors
    label#radioGroup-error {
      display: block;
      border-top: 2px solid $bright-red;
    }
  
  }

  ///////////////////////////////////////////////
//////////////////////////////////////////////
///////// CUSTOM INPUTS //////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////
.input, .textarea {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: .25em 0 0 0;
  //max-width: 350px;
  //width: calc(100% - 2em);
  width: 100%;
  vertical-align: top;
  &.custom {
    overflow: hidden;
  }

  .field {
    position: relative;
    display: block;
    float: right;
    padding: 0.8em;
    width: 60%;
    border: none;
    border-radius: 0;
    //background: #f0f0f0;
    color: $light-blue;
    -webkit-appearance: none; /* for box shadows to show on iOS */
    &:focus {
      outline: none;
    }
    &.custom {
      margin-top: 1em;
      //padding: 1.25em 0.813em;
      padding: 1.25em .813em .3em .65em;
      width: 100%;
      @include RobotoCondensedRegular;
      background:$light-gray;
      color: $light-blue;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 30px;
    }
  }

  .label {
    @include RobotoCondensedRegular;
    display: inline-block;
    float: right;
    padding: 0 1em;
    width: 40%;
    color: $light-blue;
    //font-weight: 500;
    font-size: 70.25%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.custom {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 0;
      width: 100%;
      height: calc(100% - 1em);
      text-align: left;
      pointer-events: none;
      &.bright-blue::after {
        //border-color: hsl(200, 100%, 50%);
        border-color: $bright-blue;
      }

      .bright-red::after {
        //border-color: hsl(160, 100%, 50%);
        border-color: $bright-red;
      }
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 0px);
        border-bottom: 2px solid $light-blue;
      }
      &::after {
        margin-top: 2px;
        border-bottom: 4px solid red;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
      }
    }

    .content {
      position: relative;
      display: block;
      //padding: 1.6em 0.813em;
      padding: 1.5em .813em;
      width: 100%;
      &.custom {
        position: absolute;
      }
    }
  }
}



.field.custom:focus + .label.custom::after,
.filled .label.custom::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  color:$bright-blue;
}

.field.custom:focus + .label.custom .content.custom,
.filled .content.custom {
  -webkit-animation: input-anim  0.3s forwards;
  animation: input-anim  0.3s forwards;
  color:$bright-blue;
  font-size:14px;
  line-height: 0;
}

@-webkit-keyframes input-anim {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -10%, 0);
    transform: translate3d(-1em, -10%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}

@keyframes input-anim  {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -10%, 0);
    transform: translate3d(-1em, -10%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}

///////////////////////////////////
///////////////////////////////////
/////////// Text Area /////////////
//////////////////////////////////
///////////////////////////////////

.textarea {
  width: 100% !important;
  textarea {
    min-height:250px;
  }
}

///////////////////////////////////////////////
//////////////////////////////////////////////
///////////////// SELECTS ////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////

/* Default custom select styles */
.mq-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  background: $white;
  border-bottom:2px solid $light-blue;
  z-index: 100;
  width: 100%;
  height:50px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none; /* For better accessibility add a style for this in your skin */
  }

  &.mq-active {
    z-index: 200;

    .mq-options {
      visibility: visible;
    }
  }

  &.mq-skin-border {
    @media screen and (max-width: 30em) {
      font-size: 1em;
    }

    &.mq-active {

      > span {
        background: $white;
        border-color: $bright-blue;
        color: $bright-blue;

        &::after {
          -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    > span {
      border:none;
      border-bottom: 2px solid $dark-blue;
      border-color: inherit;
      -webkit-transition: background 0.2s, border-color 0.2s;
      transition: background 0.2s, border-color 0.2s;
    }
  }

  span {
    @include RobotoCondensedRegular;
    font-size:18px;
    display: block;
    position: relative;
    height:inherit;
    cursor: pointer;
    padding: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span {
    padding-right: 3em;

    &::after {
      content: '\e909';
      right: 1em;
      speak: none;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

  }

  select {
    display: none;
  }
  /* Options */
  .mq-options {
    position: absolute;
    overflow: hidden;
    width: 100%;
    background: $white;
    visibility: hidden;
    margin-top:-3rem;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        padding: 0 0;

        span {
          padding: 1em;

          &:hover {
            background: $light-gray;
          }
        }
      }
    }
  }
}


//.mq-select > span::after,
//.mq-select .mq-selected span::after {
//  speak: none;
//  position: absolute;
//  top: 50%;
//  -webkit-transform: translateY(-50%);
//  transform: translateY(-50%);
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

//.mq-select > span::after {
//  content: '\e900';
//  right: 1em;
//}

//.mq-select .mq-selected span::after {
//  content: '\e900';
//  margin-left: 1em;
//}

//.mq-select.mq-active > span::after {
//  -webkit-transform: translateY(-50%) rotate(180deg);
//  transform: translateY(-50%) rotate(180deg);
//}


.mq-select ul li.mq-focus span {
  background-color: #ddd;
}

/* Optgroup and optgroup label */
.mq-select li.mq-optgroup ul {
  padding-left: 1em;
}

.mq-select li.mq-optgroup > span {
  cursor: default;
}


//.mq-skin-border {
//  background: transparent;
//}

//@media screen and (max-width: 30em) {
//  .mq-skin-border { font-size: 1em; }
//}
//
//.mq-skin-border > span {
//  border:none;
//  border-bottom: 2px solid $dark-blue;
//  border-color: inherit;
//  -webkit-transition: background 0.2s, border-color 0.2s;
//  transition: background 0.2s, border-color 0.2s;
//}

.mq-skin-border > span::after,
.mq-skin-border .mq-selected span::after {
  font-family: 'mq';
  content: '\e909';
  font-size:12px;
}

.mq-skin-border ul span::after {
  content: '';
  opacity: 0;
}

.mq-skin-border .mq-selected span::after {
  content: '\e909';
  color: #ddd9c9;
  font-size: 1.5em;
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

//.mq-skin-border.mq-active > span {
//  background: #fff;
//  border-color: #fff;
//  color: #2980b9;
//}

.mq-skin-border .mq-options {
  color: #2980b9;
  font-size: 0.75em;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.mq-skin-border.mq-active .mq-options {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.mq-skin-border ul span {
  padding: 1em 2em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.mq-skin-border .mq-options li span:hover,
.mq-skin-border li.mq-focus span {
  background: #f5f3ec;
}


///////////////////////////////////////////////
//////////////////////////////////////////////
///////// CUSTOM RADIOS & CHECKBOXES //////////
///////////////////////////////////////////////
//////////////////////////////////////////////
[type="checkbox"],
[type="radio"] {

  &:focus {

    + label {

      &::before {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4) !important;
      }
    }
  }

  &:hover {

    + label {

      &::before {
        border-color: $mq-check-focus-border-color;
      }
    }
  }

  &:active {

    + label {

      &::before {
        transition-duration: 0;
      }
    }
  }

  + label {
    background:$white;
    position: relative;
    padding: 0 $mq-input-padding;
    user-select: none;
    line-height:3;
    color: $light-blue;
    font-size: 18px;
    letter-spacing: 0.5px;
    @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
      font-size: 16px;
    }
    //line-height: 30px;

    &.block {
      display:block;
    }

    &.inline {
      // padding:1rem;
      margin-right:1rem;
        &:last-of-type {
          margin-right:0;
        }

      &::after {
        // left: $mq-input-padding + $mq-check-border-width + $mq-check-scale + 6;
        left: 12px;
      }
      @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
        display:block;
        margin-left:0;
      }
    }

    &::before {
      background-color: $mq-check-input-bg-color;
      border: $mq-check-border-width solid $mq-check-border-color;
      box-sizing: content-box;
      content: '';
      color: $mq-check-border-color;
      margin-right: $mq-check-width * 0.25;
      top: 50%;
      left: 0;
      width: $mq-check-width;
      height: $mq-check-height;
      display: inline-block;
      vertical-align: middle;
    }

    &::after {
      box-sizing: content-box;
      content: '';
      background-color: $mq-check-border-color;
      position: absolute;
      top: 50%;
      left: $mq-input-padding + $mq-check-border-width + $mq-check-scale/2;
      width: $mq-check-width - $mq-check-scale;
      height: $mq-check-height - $mq-check-scale;
      margin-top: ($mq-check-height - $mq-check-scale)/-2;
      transform: scale(0);
      transform-origin: 50%;
      transition: transform 200ms ease-out;
    }
  }

  &[disabled] {

    + label {

      &::before {
        animation: none;
        box-shadow: none;
        border: 1px solid rgba(128, 128, 128, 0.5);
      }
    }

    &:active,
    &:focus,
    &:hover {

      + label {

        &::before {
          border-color: rgba(128, 128, 128, 0.5);
          filter: none;
          transition: none;
        }
      }
    }
  }
}

[type="checkbox"] {

  + label {

    &::before,
    &::after {
    //$if ($mq-check-border-radius) {
      border-radius: $mq-check-border-radius;
    //}
    //$else {
    //  border-radius: 0;
    //}
    }
    &::before {
      background-color: $white;
      border: $mq-check-border-width solid $mq-check-input-bg-color;
    }

    &::after {
      background-color: transparent;
      top: 50%;
      @media (max-width: 767px) {
        //top: 30%;
      }
      left: calc(#{$mq-input-padding} + #{$mq-check-border-width} + #{$mq-check-width}/5);
      width: $mq-check-width/2;
      height: $mq-check-width/5;
      margin-top: calc(#{$mq-check-height} / -2 / 2 * 0.8);
      border-style: solid;
      border-color: $mq-check-border-color;
      border-width: 0 0 3px 3px;
      border-radius: 0;
      border-image: none;
      transform: rotate(-45deg) scale(0);
      transition: none;
    }
  }

  &:checked {

    + label {

      &::before {
        background-color: $mq-check-input-bg-color;
      }

      &::after {
        content: '';
        transform: rotate(-45deg) scale(1);
        transition: transform 200ms ease-out;
      }
    }
  }
}

[type="radio"] {

  + label {

    &::before,
    &::after {
    //$if ($mq-check-border-radius) {
      border-radius: 50%;
    //}
    //$else {
    //  border-radius: 50%;
    //}
    }

    &::before {
      background-color: $white;
      border: $mq-check-border-width solid $gray;
      color: $mq-check-input-bg-color;
    }

    &::after {
      background-color: $mq-check-input-bg-color;
    }
  }

  &:checked {

    &:active,
    &:focus {

      + label {

        &::before {
          animation: none;
          filter: none;
          transition: none;
        }
      }
    }

    + label {

      &::before {
        animation: none;
        background-color: $white;
        border: 2px solid #012842;
      }

      &::after {
        margin-top:-8px;
        transform: scale(0.85);
        @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
          margin-top:-9px;
        }
        &:focus {
          outline:none;
        }
      }
    }
  }
}

//required
span.required {
  @include NunitoRegular;
  font-size: 1.25em;
  color: $bright-red;
  vertical-align: top;
}

//input group label
.input-group-label {
  color: $dark-blue;
  @include RobotoCondensedBold;
  padding-bottom: 20px;
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.51px;
  line-height: 20px;
  text-transform: uppercase;
  @media(min-width:$screen-xs) and ( max-width:$screen-xs-max) {
    font-size: 17px;
  }
  .required {
    line-height: 1.2;
  }
}

//top required key
fieldset>.input>span.label {
  text-align: right;
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #900;
  }
}

////////////////////////////////////
///////////////////////////////////
////// Validation Display ///////
//////////////////////////////////
///////////////////////////////////
// form {

//   //underline red
//   input.error+label.error+label:before, input.error+label.error+.label.custom.bright-blue:after {
//     border-bottom: 2px solid $bright-red !important;
//   }
//   //space for the error message
//   input.error+label.error+label, input.error+label.error+.label.custom.bright-blue {
//     margin-bottom: 20px;
//   }
//   //adjust the display
//   .input input.error+.error+.label .content {
//     margin-top: 20px;
//   }
//   //text red
//   .error {
//     color: $bright-red;
//   }
//   //radio group errors
//   label#radioGroup-error {
//     display: block;
//     border-top: 2px solid $bright-red;
//   }

// }

//////////////////////////////////////////////
//////////////////////////////////////////////
////// Contact Form Validation Display ///////
//////////////////////////////////////////////
//////////////////////////////////////////////

form.contact-us .cell.medium-6.has-feedback .fas.fa-check-circle {
  color: #74cf6a;
}
form.contact-us .cell.medium-6.has-feedback label {
  position: relative;
}

form.contact-us .fa-exclamation-circle, 
form.contact-us .fa-times-circle {
  color:$bright-red;
  vertical-align: middle;
}

form.contact-us .cell.medium-6.has-feedback i.fas {
  position: absolute;
  top: 42px;
  right: 10px;
}

form.contact-us  .cell.medium-6.has-feedback .help-block.error {
  color: $bright-red;
  font-size: 12px;margin-top:5px;
  font-weight:600;
}

form.contact-us .cell.medium-6.has-feedback.has-success .form-control {
  border: 2px solid #74cf6a;
}

form.contact-us .cell.medium-6.has-feedback.has-error .form-control {
  border: 2px solid $bright-red;
  margin-bottom: 0;
}