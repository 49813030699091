.community {

    background-color:$white;
    background-image: url("/img/waves/wave-community.png"), url("/img/content/about/community.png");
	background-size: contain, cover;
	background-position:top center, center center;
    background-repeat: no-repeat;
    min-height:700px;

    @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
        background-image: url("/img/waves/wave-community.png");
        background-size: contain;
        background-position:top center;
        background-repeat: no-repeat;
        background-color:#099493;
        min-height:400px;
    }


    &.desktop-view {
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            display: none;
        }
    }
    &.mobile-tablet-view {
        display: none;
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            display:block;
        }
    }

    &.image {
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            background-image:  url("/img/waves/wave-theater-mb.png"), url("/img/waves/wave-community-mb.png"), url("/img/content/about/community-mb.png");
            background-size: contain, contain, cover;
            background-position:bottom center, top center, center center;
               
            min-height:400px;
        }
    }
  
    .custom-header {
        position:relative;
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            position:relative;
            width:280px;
            margin:75px  auto 0;
        }
        @media(min-width:$screen-sm) and (max-width:$screen-md) {
            position:relative;
            width:560px;
            margin:75px  auto 0;
        }

        h5 {
            position:absolute;
            right:23%;
            top:0;
            font-size:34px;
            line-height:28px;
            text-transform: none;

            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                right:15px;
                font-size:24.5px;
                line-height:20px;
            }
        }

        h3 {
            font-size:90.5px;
            line-height:1.48;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:65.5px;
                line-height:95.5px;
            }
        }
    }

    p {
        // margin:1.4375rem;

        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1rem;
            line-height:23.5px;
            padding: 15px;
        }
    }
 
}