.ux {
    background-color:$white;
    // background-image: url("/img/waves/wave-made-in-atl.png");
    // background-size: contain;
    // background-position:bottom center;
    // background-repeat: no-repeat;
    min-height:500px;

    h2 {
        text-transform:none;
        font-size:3.125rem;
        line-height: normal;
        @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
            font-size:2.5rem;
        }
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1.9375rem;
        }
    }
}