.industries {
    @media(min-width:$screen-xs) and (max-width:$screen-sm) {
        // background-image: url("/img/waves/wave-community-180.png");
        background-size: contain;
        background-position: bottom center;
        background-repeat:no-repeat;
    }

    .logos {
        justify-content: space-around; 
        margin-left: 6%;

        @media(min-width:460px) and (max-width:767px) {
            justify-content: flex-end; 
            margin-left: inherit;
                           }
        @media(min-width:$screen-xs) and (max-width:460px) {
            justify-content:center; 
            margin-left: inherit;
        }

    }
    
    .industry {
        display:inline-flex;
        align-items: center;
        // background-color: red;
        // width: 398px !important;
        
        
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            margin: 5px !important;
            padding: 5px !important;
            text-align: left;
            // background-color: red;
        }
        
        //  Hack for Retail Icon 
        &.retail-cell {
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                margin-left: 80px;
            }


        }
   
        img {
            max-width:67px;
            margin-right:10px;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                max-width:33px;
                margin-right:5px;
            }
        }
        h6 {
            @include NunitoMedium;
            color: $medium-blue;
            font-size:1.5rem;
            @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
                font-size:1rem;
            }
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:0.71875rem;
                line-height:14px;
            }
        }
    }
}