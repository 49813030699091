.careersPage {
   background:#071d37;



   .catsone-job-listings-title {
    font-family: 'NunitoRegular', sans-serif;
    font-size: 3.375rem;
    line-height: 4.375rem;
    letter-spacing: 0.03125rem;
    margin: 0 0;
    color: #6dd6f6;
    text-transform: capitalize;
    font-weight: inherit;
   }

}

