.collaborative {
    background-color:$light-gray;
    h1 {
        font-size:5.625rem;
        @media (min-width:$screen-sm) and (max-width:$screen-sm-max) {
            font-size:4.4375rem; 
        }
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:3.4375rem; 
        }
    }
    p {
        margin:2.5rem;
        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
             margin:1.5rem; 
        }
    }
}