.cx {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    // background-color:red !important;
    &.reimagine {
        background-color: $white;
        background-image: url(/img/waves/waves-gray-1.svg);
        // padding-bottom:13rem;
        min-height: 600px;

        //  This is a Hack I was forced to do -- Remove if copy changes on this section 
        .div-hack-for-copy {
            margin-top: 5px;

            @media(min-width:1024px) and (max-width:1155px) {
                margin-right: -64px;
            }
        }

        .reimagine-service {
            @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
                max-width: 480px;
            }
        }


        @media(min-width:$screen-sm) and (max-width:$screen-sm-max) {
            min-height: 830px;

        }

        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            min-height: 460px;
            padding-bottom: 3rem;
        }
    }

    &.measure {
        background-color: $light-gray;
        background-image: url(/img/waves/waves-gray-2.svg);
        // padding-bottom:10rem;
        min-height: 50vh;

        @media(min-width:$screen-xs) and (max-width:$screen-sm) {
            // padding-bottom:3rem;
        }
    }

    h3 {
        margin: 15px auto;

        @media(min-width:$screen-xs) and (max-width:$screen-sm) {
            margin: 0 auto;
        }
    }

}