.theater {
    // background-color:$extra-dark-blue;
    background-image: url("/img/waves/wave-theater.png"), url("/img/content/about/theater.png");
	background-size: contain, cover;
	background-position:bottom center, center center;
    background-repeat: no-repeat;
    min-height:700px;
    @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
        background-color:$medium-blue;
        background-image: none;
        min-height:350px;
    }




    &.desktop-view {
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            display: none;
        }
    }
    &.mobile-tablet-view {
        display: none;
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            display:block;
        }
    }




    &.image {
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            // background-color:$medium-blue;
            background-image: url("/img/waves/wave-theater.png"), url("/img/waves/wave-community-mb-180.png"), url("/img/content/about/theater-mb.png");
            background-size: contain, contain, cover;
            background-position:bottom center, top center, center center;
            min-height:350px;
        }
    }

    .custom-header {
        position:relative;
        width: 460px;
        margin: auto;
        @media(min-width:$screen-xs) and (max-width:$screen-sm-max) {
            position:relative;
            width:310px;
            margin:75px  auto 0;
        }
        @media(min-width:$screen-sm) and (max-width:$screen-md) {
            position:relative;
            width:460px;
            margin:75px  auto 0;
        }








        h5 {
            position:absolute;
            right:63px;
            top:5%;
            font-size:34px;
            line-height:28px;
            text-transform: none;

            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                right:35px;
                font-size:24.5px;
                line-height:20px;
            }
        }

        h3 {
            font-size:90.5px;
            line-height:1.48;
            @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
                font-size:65.5px;
                line-height:95.5px;
            }
        }
    }

    p {
        // margin:1.4375rem;
        padding: 10px;
        @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
            font-size:1rem;
            color:white;
            padding: 15px;
        }
    }

}