.hq {
    background-color: $white;
    background-image: url("/img/content/contact/contact-building-image.jpg");
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
    // min-height:35vh;
    // min-height:60vh;

    @media(min-width:$screen-xs) and (max-width:$screen-xs-max) {
        min-height: 35vh;
    }
}