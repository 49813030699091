/*----- Tabs -----*/
.tabs {
    width:100%;
    display:block;
    border:0;
  }
  
  ul.tab-links {
    padding: 0;
    margin-left:0;
    //margin: 0;
    b {
      text-align: left;
    }
    li {
      //margin: 0px 5px;
      list-style: none;
      text-align: left;
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        padding:15px 0;
      }

      //
      &.active {
        border-bottom: 2px $neon-green solid;
        a, a:hover {
          color:$light-blue;
          border-bottom: 0px $neon-green solid;
        }
      }
      a {
        display: inline;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: $light-blue;
        transition: all linear .15s;
        text-transform: uppercase;
        width: fit-content;

        @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
          line-height:1.25;
          font-size:16px;
          display: inline-block;
          width:100%;
        }

        &:hover {
          // border-bottom: 2px $neon-green solid;
        }
        i {
          display:none;
          @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
            display:inline-block;
            float:right;
            line-height:1.25;
          }
        }
      }
      &.row {
        margin: 15px 0;
      }
      //mobile accordion
      div.tab {
        &.active {
          display:block;
        }
      }
    }
    > li {
      @media (min-width:$screen-xs) and (max-width:$screen-xs-max) {
        border-bottom: 2px solid $light-gray;
        width:100%;
        line-height:1.25;
        padding:15px 0;
      }
    }
  }
  
  /*----- Content of Tabs -----*/
  .tab-content {
    padding: 15px;
    padding: 0 !important;
    margin: 0 !important;
    div {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  
  .tab {
    display:none;
  }
  .tab.active {
    display:block;
  }