/*******************************
    Core Visual Style Colors
*******************************/
/* Base Palette */
$extra-dark-blue: #071d37;
$dark-blue: #012842;
$medium-blue: #173654;
$light-blue: #1C4668;
$gray: #ebebeb;
$light-gray: #F5F4F5;
$white: #FFFFFF;

/*Screen Highlights*/
$neon-green: #00ffba;
//$bright-blue: #44C0FF;
$bright-blue: #6dd6f6;
$bright-red: #FC5661;
$yellow: #F7E669;

/*Additional Color*/
$hover-neon-green: #4dffcf;
$hover-bright-blue: $bright-blue;
$highlight:#CAEDFF;
$li-color: #6B6B6B;
$border: #e4e5ea;

/*******************************
    Media query breakpoints
*******************************/
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  300px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  1024px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1280px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);


/*-------------------
      Base Sizes
--------------------*/

/* This is the single variable that controls them all */
$emSize   : 14px;

/* The size of page text  */
$fontSize : 14px;

$x-padding:1.5rem;
$x-margin:2rem;

/*-------------------
  Dynamic Height Var
--------------------*/
$min-hght:50vh;
/*-------------------
        Sizes
--------------------*/

/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/

$miniSize        : (11 / 14);
$tinySize        : (12 / 14);
$smallSize       : (13 / 14);
$mediumSize      : (14 / 14);
$largeSize       : (16 / 14);
$bigSize         : (18 / 14);
$hugeSize        : (20 / 14);
$massiveSize     : (24 / 14);



/*-------------------
       Em Sizes
--------------------*/

/*
  This rounds $size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
// $mini            : unit( round($miniSize * $emSize) / $emSize, rem);
// $tiny            : unit( round($tinySize * $emSize) / $emSize, rem);
// $small           : unit( round($smallSize * $emSize) / $emSize, rem);
// $medium          : unit( round($mediumSize * $emSize) / $emSize, rem);
// $large           : unit( round($largeSize * $emSize) / $emSize, rem);
// $big             : unit( round($bigSize * $emSize) / $emSize, rem);
// $huge            : unit( round($hugeSize * $emSize) / $emSize, rem);
// $massive         : unit( round($massiveSize * $emSize) / $emSize, rem);

// /* em */
// $relativeMini    : unit( round($miniSize * $emSize) / $emSize, em);
// $relativeTiny    : unit( round($tinySize * $emSize) / $emSize, em);
// $relativeSmall   : unit( round($smallSize * $emSize) / $emSize, em);
// $relativeMedium  : unit( round($mediumSize * $emSize) / $emSize, em);
// $relativeLarge   : unit( round($largeSize * $emSize) / $emSize, em);
// $relativeBig     : unit( round($bigSize * $emSize) / $emSize, em);
// $relativeHuge    : unit( round($hugeSize * $emSize) / $emSize, em);
// $relativeMassive : unit( round($massiveSize * $emSize) / $emSize, em);

// /* rem */
// $absoluteMini    : unit( round($miniSize * $emSize) / $emSize, rem);
// $absoluteTiny    : unit( round($tinySize * $emSize) / $emSize, rem);
// $absoluteSmall   : unit( round($smallSize * $emSize) / $emSize, rem);
// $absoluteMedium  : unit( round($mediumSize * $emSize) / $emSize, rem);
// $absoluteLarge   : unit( round($largeSize * $emSize) / $emSize, rem);
// $absoluteBig     : unit( round($bigSize * $emSize) / $emSize, rem);
// $absoluteHuge    : unit( round($hugeSize * $emSize) / $emSize, rem);
// $absoluteMassive : unit( round($massiveSize * $emSize) / $emSize, rem);

/*-------------------
       Element
--------------------*/

/* Button */
// $verticalMargin: 0em;
// $horizontalMargin: 0em;
// $backgroundColor: #E0E1E2;
// $backgroundImage: none;
// $background: $backgroundColor $backgroundImage;
// $lineHeight: 1em;

/* Button defaults to using same height as input globally */
// $verticalPadding: 1.5em;
// $horizontalPadding: 1.5em;

/* Text */
// $textTransform: none;
// $textTransformUpper: uppercase;
// $tapColor: transparent;
// $fontFamily: $pageFont;
// $fontWeight: bold;
// $textColor: $light-blue;
// $textShadow: none;
// $invertedTextShadow: $textShadow;
// $borderRadius: $defaultBorderRadius;
// $verticalAlign: baseline;

/* Button */
// $buttonFontFamily: $buttonFont;

/* Internal Shadow */
// $shadowDistance: 0em;
// $shadowOffset: ($shadowDistance / 2);
// $shadowBoxShadow: 0px -$shadowDistance 0px 0px $borderColor inset;

/* Box Shadow */
// $borderBoxShadowColor: transparent;
// $borderBoxShadowWidth: 1px;
// $borderBoxShadow: 0px 0px 0px $borderBoxShadowWidth $borderBoxShadowColor inset;
// $boxShadow:
// $borderBoxShadow,
// $shadowBoxShadow
// ;

/*--------------
   Form Input
---------------*/
// custom inputs
// custom checkbox/radios
$mq-input-padding:6px;
$mq-check-height:28px;
$mq-check-width:$mq-check-height;
$mq-check-scale:8px;
$mq-check-input-bg-color:$bright-blue;
$mq-check-border-width:2px;
$mq-check-border-radius:4px;
$mq-radio-border-radius:12px;
$mq-check-border-color:$white;
$mq-check-focus-color:#333;
$mq-check-focus-bg-color:$mq-check-input-bg-color;
$mq-check-focus-border-color:$black;


/*******************************
             Forms
*******************************/
/* Form */
// $gutterWidth: 1em;
// $rowDistance: 1em;

/* Text */
// $paragraphMargin: $rowDistance 0em;

/* Field */
// $fieldMargin: 0em 0em $rowDistance;

/* Fields */
// $fieldsMargin: 0em -($gutterWidth / 2) $rowDistance;

/* Form Label */
// $labelDistance: 4px;
//$labelMargin: 0em 0em $labelDistance 0em;
// $labelMargin: 0em 0em 0em 1em;
// $labelFontSize: 14px;
// $labelFontWeight: bold;
// $labelTextTransform: none;
// $labelColor: $textColor;

/* Input */
// $inputFont: $pageFont;
// $inputWidth: 100%;
// $inputFontSize: 1em;
// $inputPadding: ($inputVerticalPadding + ((1em - $inputLineHeight) / 2)) $inputHorizontalPadding;
// $inputBorder: 2px solid $borderColor;
//$inputBorderRadius: $absoluteBorderRadius;
// $inputBorderRadius: 0;
// $inputColor: $textColor;
// $inputTransition:
// color $defaultDuration $defaultEasing,
// border-color $defaultDuration $defaultEasing
// ;
// $inputBoxShadow: 0em 0em 0em 0em transparent inset;

/* Select */
// $selectBackground: $white;
// $selectBorderRadius: $inputBorderRadius;
// $selectBorder: $inputBorder;
// $selectPadding: 0.62em $inputHorizontalPadding;
// $selectBoxShadow: $inputBoxShadow;
// $selectTransition: $inputTransition;
// $selectColor: $inputColor;

/* Text Area */
// $textAreaPadding: $inputVerticalPadding $inputHorizontalPadding;
// $textAreaHeight: 12em;
// $textAreaResize: vertical;
// $textAreaLineHeight: 1.2857;
// $textAreaMinHeight: 8em;
// $textAreaMaxHeight: 24em;
// $textAreaBackground: $inputBackground;
// $textAreaBorder: $inputBorder;
// $textAreaFontSize: $inputFontSize;
// $textAreaTransition: $inputTransition;

/* Checkbox */
// $checkboxVerticalAlign: top;
// $checkboxLabelFontSize: 1em;
// $checkboxLabelTextTransform: $labelTextTransform;

/* Inline Validation Prompt */
// $promptBackground: $white;
// $promptBorderColor: $formErrorBorder;
// $promptBorder: 1px solid $promptBorderColor;
// $promptTextColor: $formErrorColor;
// $inlinePromptMargin: -0.25em 0em -0.5em 0.5em;
// $inlinePromptBorderWidth: 1px;

/*-------------------
        States
--------------------*/

/* Focus */
// $inputFocusPointerSize: 0px;

/* Input Focus */
// $inputFocusBackground: $inputBackground;
// $inputFocusBorderColor: $focusedFormBorderColor;
// $inputFocusColor: $selectedTextColor;
// $inputFocusBoxShadow: $inputFocusPointerSize 0em 0em 0em $selectedBorderColor inset;
// $inputFocusBorderRadius: $inputBorderRadius;

/* Text Area Focus */
// $textAreaFocusBackground: $inputFocusBackground;
// $textAreaFocusBorderColor: $inputFocusBorderColor;
// $textAreaFocusColor: $inputFocusColor;
// $textAreaFocusBoxShadow: $inputFocusBoxShadow;
// $textAreaFocusBorderRadius: $inputFocusBorderRadius;

/* Disabled */
// $disabledLabelOpacity: $disabledOpacity;

/* Errored Input */
// $formErrorColor: $negativeTextColor;
// $formErrorBorder: $negativeBorderColor;
// $formErrorBackground: $negativeBackgroundColor;

/* AutoFill */
// $inputAutoFillBackground: #FFFFF0;
// $inputAutoFillBorder: #E5DFA1;
// $inputAutoFillFocusBackground: $inputAutoFillBackground;
// $inputAutoFillFocusBorder: #D5C315;
// $inputAutoFillErrorBackground: #FFFAF0;
// $inputAutoFillErrorBorder: #E0B4B4;


/* Input Error */
// $inputErrorBorderRadius: '';
// $inputErrorBoxShadow: none;

/* Dropdown Error */
// $dropdownErrorHoverBackground: #FBE7E7;
// $dropdownErrorSelectedBackground: $dropdownErrorHoverBackground;
// $dropdownErrorActiveBackground: #FDCFCF;
// $dropdownErrorLabelBackground: #EACBCB;
// $dropdownErrorLabelColor: $errorTextColor;

/* Focused Error */
// $inputErrorFocusBackground: $negativeBackgroundColor;
// $inputErrorFocusColor: $negativeTextColor;
// $inputErrorFocusBorder: $negativeBorderColor;
// $inputErrorFocusBoxShadow: none;

/* Placeholder Error */
// $inputErrorPlaceholderColor: lighten($formErrorColor, 40);
// $inputErrorPlaceholderFocusColor: lighten($formErrorColor, 30);







