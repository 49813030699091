.outcome {
    min-height:600px;
    @media(min-width:$screen-xs) and (max-width:$screen-sm) {
        min-height:60vh;
    }
    .icon {
        padding:1rem;
        max-width:325px;
        margin:0 auto;
        @media(min-width:$screen-xs) and (max-width:$screen-sm) {
            padding:0 0;
            max-width:275px;
        }
    }

    .mobile {
        transition:opacity 0.1s ease-in-out;
        .mobile-1 {
            opacity:1;
            transition:opacity 0.1s ease-in-out;
        }
        .mobile-2 {
            display:none;
            opacity:0;
        }
        &:hover {
            .mobile-1 {
                opacity:0;
                transition:opacity 0.1s ease-in-out;
                display:none;
            }
            .mobile-2 {
                display:block;
                opacity:1;
            }
        }
    }
    .tag {
        transition:opacity 0.1s ease-in-out;
        .tag-1 {
            opacity:1;
            transition:opacity 0.1s ease-in-out;
        }
        .tag-2 {
            display:none;
            opacity:0;
        }
        &:hover {
            .tag-1 {
                opacity:0;
                transition:opacity 0.1s ease-in-out;
                display:none;
            }
            .tag-2 {
                display:block;
                opacity:1;
            }
        }
    }

}