/**************************************************

                    BOOTSTRAP

 **************************************************/
// Responsive utilities
$font-path:    '../../fonts';
//
// More easily include all the states for responsive-utilities.less.
@mixin responsive-visibility {
    display: block !important;
    table  { display: table; }
    tr     { display: table-row !important; }
    th,
    td     { display: table-cell !important; }
  }
  
  @mixin responsive-invisibility {
    display: none !important;
  }
  
  /**************************************************
  
                        ROBOTO
  
  ***************************************************/
  
  @mixin RobotoRegular {
    font-family: 'RobotoRegular', sans-serif;
  }
  
  @mixin RobotoBlack {
    font-family: 'RobotoBlack', sans-serif;
  }
  
  @mixin RobotoCondensedRegular {
    font-family: 'RobotoCondensedRegular', sans-serif;
  }
  
  @mixin RobotoCondensedBold {
    font-family: 'RobotoCondensedBold', sans-serif;
  }
  
  
  /**************************************************
  
                        NUNITO
  
  ***************************************************/
  
  @mixin NunitoRegular {
    font-family: 'NunitoRegular', sans-serif;
  }
  
  @mixin NunitoMedium {
    font-family: 'NunitoMedium', sans-serif;
  }
  @mixin NunitoSemiBold {
    font-family: 'NunitoSemiBold', sans-serif;
  }
  
  @mixin NunitoBold {
    font-family: 'NunitoBold', sans-serif;
  }
  
  /**************************************************
  
                    RALEWAY BLACK
  
  ***************************************************/
  
  @mixin RalewayBlack {
    font-family: 'RalewayBlack', sans-serif;
  }
  
  
  /***********************************************
  
              VISUALLy HIDE ELEMENTS
  
  *************************************************/
  
  
  @mixin vh {
    border: 0                      !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%)          !important;
    height: 1px                    !important;
    overflow: hidden               !important;
    padding: 0                     !important;
    position: absolute             !important;
    width: 1px                     !important;
    white-space: nowrap            !important;
  }
  
  @mixin vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    }
  
//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}